import Label from "components/Label/Label";
import React, { FC, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Input from "shared/Input/Input";
import Radio from "shared/Radio/Radio";
import Select from "shared/Select/Select";
import toast from "react-hot-toast";
interface Props {
  isActive: boolean;
  onCloseActive: () => void;
  onOpenActive: () => void;
  onChange: (newShippingAddress: ShippingAddressData) => void;
}

interface ShippingAddressData {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  address: string;
  aptSuite: string;
  state: string;
  city: string;
  country: string;
  stateProvince: string;
  postalCode: string;
  addressType: string;
}

const ShippingAddress: FC<Props> = ({
  isActive,
  onCloseActive,
  onOpenActive,
  onChange,
}) => {
  // Create state variables for each input field
  const [shippingAddressData, setShippingAddressData] = useState<ShippingAddressData>({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    address: "",
    aptSuite: "",
    state: "",
    city: "",
    country: "",
    stateProvince: "",
    postalCode: "",
    addressType: "Home", // Default value
  });

  // Function to handle input change
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = event.target;
    const lettersAndSpacesOnly = /^[A-Za-z\s]*$/;
    const digitsOnly = /^\d*$/;


    if (name === "firstName" || name === "lastName") {
      // Validate the input for firstName and lastName
      if (!lettersAndSpacesOnly.test(value)) {
        // Optionally, show an error message or handle invalid input
        console.log("Invalid input. Only letters and spaces are allowed.");
        return; // Do not update the state if the value is invalid
      }
    } else if (name === "phone") {
      // Validate the input for phone
      if (!digitsOnly.test(value) || value.length > 10) {
        console.log("Invalid input. Only digits are allowed and maximum length is 10.");
        return; // Do not update the state if the value is invalid
      }
    } else if (name === "postalCode") {
      // Validate the input for phone
      if (!digitsOnly.test(value) || value.length > 6) {
        console.log("Invalid input. Only digits are allowed and maximum length is 10.");
        return; // Do not update the state if the value is invalid
      }
    }
    // Update the state with the new input data
    setShippingAddressData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    // Call the onChange prop with the updated data
    onChange(shippingAddressData);
  };

  // Function to handle radio button change
  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    // Update the state with the new input data
    setShippingAddressData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    // Call the onChange prop with the updated data
    onChange(shippingAddressData);
  };

  // ...
  const handleSubmit = () => {
    if (
      shippingAddressData.firstName.length > 0 &&
      shippingAddressData.lastName.length > 0 &&
      shippingAddressData.phone.length > 0 &&
      shippingAddressData.email.length > 0 &&
      shippingAddressData.address.length > 0 &&
      shippingAddressData.aptSuite.length > 0 &&
      shippingAddressData.state.length > 0 &&
      shippingAddressData.city.length > 0 &&
      shippingAddressData.postalCode.length > 0
    ) {
      if (shippingAddressData.phone.length !== 10) {
        toast.error("Phone number must be 10 digits.");
        return;
      }
      var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
      if (!regex.test(shippingAddressData.email)) {
        toast.error("Enter valid email");
        return;
      }
      onChange(shippingAddressData);
      onCloseActive();
    } else {
      toast.error("All field required");
      return; // Stop the form submission process
    }
  };

  return (
    <div className="border border-slate-200 dark:border-slate-700 rounded-xl ">
      <div className="p-6 flex flex-col sm:flex-row items-start">
        <span className="hidden sm:block">
          <svg
            className="w-6 h-6 text-slate-700 dark:text-slate-400 mt-0.5"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.1401 15.0701V13.11C12.1401 10.59 14.1801 8.54004 16.7101 8.54004H18.6701"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M5.62012 8.55005H7.58014C10.1001 8.55005 12.1501 10.59 12.1501 13.12V13.7701V17.25"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M7.14008 6.75L5.34009 8.55L7.14008 10.35"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M16.8601 6.75L18.6601 8.55L16.8601 10.35"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>

        <div className="sm:ml-8">
          <h3 className=" text-slate-700 dark:text-slate-300 flex ">
            <span className="uppercase">SHIPPING ADDRESS</span>
            <svg
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="2.5"
              stroke="currentColor"
              className="w-5 h-5 ml-3 text-slate-900 dark:text-slate-100"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4.5 12.75l6 6 9-13.5"
              />
            </svg>
          </h3>
          <div className="font-semibold mt-1 text-sm">
            <span className="">
              {shippingAddressData.address}
            </span>
          </div>
        </div>
        <ButtonSecondary
          sizeClass="py-2 px-4 "
          fontSize="text-sm font-medium"
          className="bg-slate-50 dark:bg-slate-800 mt-5 sm:mt-0 sm:ml-auto !rounded-lg"
          onClick={onOpenActive}
        >
          Change
        </ButtonSecondary>
      </div>
      <div
        className={`border-t border-slate-200 dark:border-slate-700 px-6 py-7 space-y-4 sm:space-y-6 ${isActive ? "block" : "hidden"
          }`}
      >
        {/* ============ */}
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-3">
          <div>
            <Label className="text-sm">First name</Label>
            <Input className="mt-1.5" name="firstName" value={shippingAddressData.firstName} onChange={handleInputChange} />
          </div>
          <div>
            <Label className="text-sm">Last name</Label>
            <Input className="mt-1.5" name="lastName" value={shippingAddressData.lastName} onChange={handleInputChange} />
          </div>
        </div>

        {/* ============ */}
        <div className="sm:flex space-y-4 sm:space-y-0 sm:space-x-3">
          <div className="flex-1">
            <Label className="text-sm">Address</Label>
            <Input
              className="mt-1.5"
              placeholder=""
              type={"text"}
              name="address"
              value={shippingAddressData.address} onChange={handleInputChange}
            />
          </div>
          <div className="sm:w-1/3">
            <Label className="text-sm">Apt, Suite *</Label>
            <Input className="mt-1.5" name="aptSuite" value={shippingAddressData.aptSuite} onChange={handleInputChange} />
          </div>
        </div>
        <div className="sm:flex space-y-4 sm:space-y-0 sm:space-x-3">
          <div className="sm:w-1/3">
            <Label className="text-sm">Number</Label>
            <Input
              className="mt-1.5"
              placeholder=""
              type={"number"}
              name="phone"
              value={shippingAddressData.phone} onChange={handleInputChange}
            />
          </div>
          <div className="flex-1">
            <Label className="text-sm">Email *</Label>
            <Input className="mt-1.5" name="email" value={shippingAddressData.email} onChange={handleInputChange} />
          </div>

        </div>

        {/* ============ */}
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-3">
          <div>
            <Label className="text-sm">City</Label>
            <Input className="mt-1.5" name="city" value={shippingAddressData.city} onChange={handleInputChange} />
          </div>
          <div>
            <Label className="text-sm">State/Province</Label>
            <Input className="mt-1.5" name="state" value={shippingAddressData.state} onChange={handleInputChange} />
          </div>
          {/* <div>
              <Label className="text-sm">Country</Label>
              <Select className="mt-1.5" defaultValue="United States ">
                <option value="United States">United States</option>
                <option value="United States">Canada</option>
                <option value="United States">Mexico</option>
                <option value="United States">Israel</option>
                <option value="United States">France</option>
                <option value="United States">England</option>
                <option value="United States">Laos</option>
                <option value="United States">China</option>
              </Select>
            </div> */}
        </div>
        {/* ============ */}
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-3">
          <div>
            <Label className="text-sm">Postal code</Label>
            <Input className="mt-1.5" name="postalCode" value={shippingAddressData.postalCode} onChange={handleInputChange} />
          </div>
        </div>

        {/* ============ */}
        {/* <div>
            <Label className="text-sm">Address type</Label>
            <div className="mt-1.5 grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-3">
              <Radio
                label={`<span class="text-sm font-medium">Home <span class="font-light">(All Day Delivery)</span></span>`}
                id="Address-type-home"
                name="Address-type"
                defaultChecked
              />
              <Radio
                label={`<span class="text-sm font-medium">Office <span class="font-light">(Delivery <span class="font-medium">9 AM - 5 PM</span>)</span> </span>`}
                id="Address-type-office"
                name="Address-type"
              />
            </div>
          </div> */}

        {/* ============ */}
        <div className="flex flex-col sm:flex-row pt-6">
          <ButtonPrimary
            className="sm:!px-7 shadow-none"
            onClick={handleSubmit}
          >
            Save and next to Payment
          </ButtonPrimary>
          <ButtonSecondary
            className="mt-3 sm:mt-0 sm:ml-3"
            onClick={onCloseActive}
          >
            Cancel
          </ButtonSecondary>
        </div>
      </div>
    </div>
  );
};

export default ShippingAddress;
