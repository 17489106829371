import React, { FC, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import LikeButton from "components/LikeButton";
import AccordionInfo from "./AccordionInfo";
import { StarIcon } from "@heroicons/react/24/solid";
import BagIcon from "components/BagIcon";
import NcInputNumber from "components/NcInputNumber";
import { PRODUCTS } from "data/data";
import axios from "axios";
import { api_url, api_assets_url } from "../../common/Helpers";
import {
  NoSymbolIcon,
  ClockIcon,
  SparklesIcon,
} from "@heroicons/react/24/outline";
import IconDiscount from "components/IconDiscount";
import Prices from "components/Prices";
import toast from "react-hot-toast";
import SectionSliderProductCard from "components/SectionSliderProductCard";
import detail1JPG from "images/products/detail1.jpg";
import detail2JPG from "images/products/detail2.jpg";
import detail3JPG from "images/products/detail3.jpg";
import Policy from "./Policy";
import ReviewItem from "components/ReviewItem";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import SectionPromo2 from "components/SectionPromo2";
import ModalViewAllReviews from "./ModalViewAllReviews";
import NotifyAddTocart from "components/NotifyAddTocart";
// Import the custom cart context and action
import { useCart } from "../../context/Context";
export interface ProductDetailPageProps {
  className?: string;
}
// Define the interface for the API response
interface ApiResponse {
  pdata: {
    id: number;
    pname: string;
    price: number;
    bdescription: string;
    fabriccare:string;
    howitfits:string;
    faq:string;
    size: string;
    allOfSizes: string;
    ddescription: string;
    variantType: string;
    status: string;
    slength: string;
    armhole: string;
    chest: string;
    metatitle: string;
    metades: string;
    metaimage: string;
    image: string;
    categoryid: string;
    categoryname: string;
    name: string;
    content: string;
    allattribute: {
      name: string[]; // Assuming 'name' is an array of strings
      price: number[]; // Assuming 'price' is an array of numbers
    };
  };
  featuresimages: {
    name: string;
    lastid: string;
  }[];
  selectedsizes: {
    value: string;
    label: string;
  }[];
}
interface StockItem {
  id: number;
  productid: string;
  productname: string;
  psize: string;
  pcolor: string;
  pquantity: number;
}
const ProductDetailPage: FC<ProductDetailPageProps> = ({ className = "" }) => {
  const { variants, status } = PRODUCTS[0];

  const LIST_IMAGES_DEMO = [detail1JPG, detail2JPG, detail3JPG];

  // Access the mainid parameter using useParams
  const { mainid } = useParams();
  const [productdata, setProductData] = useState<ApiResponse | null>(null);
  const [stockqtydata, setStockqtydata] = useState<StockItem[]>([]);
  useEffect(() => {
    // Make an HTTP GET request to the API
    axios
      .get(api_url + "getproductbyid/" + mainid)
      .then((response) => {
        console.log(response)
        setProductData(response.data);
        setStockqtydata(response.data.sizecolorqty);
        if (

          response.data.pdata.allOfSizes &&
          response.data.pdata.allOfSizes.length > 0
        ) {
          setSizeSelected(response.data.pdata.allOfSizes[0]);
        }
        if (response.data) {
          setnewprice(response.data.pdata.allattribute.price[0])
          setvariantname(response.data.pdata.allattribute.name[0])
        }

      })
      .catch((error) => {
        console.error("Error fetching product data:", error);
      });
  }, []);
  const { allOfSizes, size } = productdata?.pdata || {};
  const [variantname, setvariantname] = React.useState(
    productdata?.pdata.allattribute.name[0]
  );
  const [variantActive, setVariantActive] = React.useState(0);
  const [sizeSelected, setSizeSelected] = React.useState(size ? size[0] : "");
  const [qualitySelected, setQualitySelected] = React.useState(1);
  const [quantity, setQuantity] = useState<number | null>(null);
  console.log("color", variantname);
  console.log("size", sizeSelected);
  console.log("qty", quantity);
  console.log("qty", typeof (quantity));
  console.log("stock", stockqtydata);
  useEffect(() => {
    // Ensure that variantname is "pcolor" and sizeSelected is "psize"
    if (variantname && sizeSelected) {
      // Find the item with matching pcolor and psize in stockqtydata
      const matchingItem = stockqtydata.find(
        (item) => item.pcolor === variantname && item.psize === sizeSelected
      );

      // If a matching item is found, set the quantity to its pquantity
      if (matchingItem) {
        setQuantity(Number(matchingItem.pquantity));
      } else {
        // If no matching item is found, set quantity to null or any default value you prefer
        setQuantity(null);
      }
    } else {
      // If variantname or sizeSelected don't match the criteria, set quantity to null
      setQuantity(null);
    }
  }, [variantname, sizeSelected, stockqtydata]);

  const [isOpenModalViewAllReviews, setIsOpenModalViewAllReviews] =
    useState(false);
  // const [newprice, setnewprice] = React.useState(productdata?.pdata.price[0] ? Number(productdata?.pdata.price[0]) : price);

  const [newprice, setnewprice] = React.useState(
    typeof productdata?.pdata.price === 'number'
      ? productdata?.pdata.price
      : typeof productdata?.pdata.price[0] === 'number'
        ? productdata?.pdata.price[0]
        : 0  // Provide a default value if price is not a number
  );
  const { dispatch } = useCart();
  const notifyAddTocart = () => {
    // Create an action object to add the product to the cart
    const product_p = Number(newprice ? newprice : productdata?.pdata.price);
    const action = {
      type: "ADD_TO_CART", // Use the appropriate action type from your context
      payload: {
        id: productdata?.pdata.id,
        name: productdata?.pdata.pname,
        price: product_p * qualitySelected,
        unitPrice: product_p,
        description: productdata?.pdata.bdescription,
        size: sizeSelected,
        variants: productdata?.pdata.size,
        variantType: productdata?.pdata.variantType,
        status: productdata?.pdata.status,
        image: productdata?.pdata.image,
        allattribute: variantname,
        havestock: quantity,
        qty: qualitySelected,
      },
    };
    // Dispatch the action to add the product to the cart
    dispatch(action);
    let myprice = productdata?.pdata.price;
    toast.custom(
      (t) => (
        <NotifyAddTocart
          title={productdata?.pdata.pname}
          price={newprice ? qualitySelected * newprice : (productdata?.pdata.price)}
          productImage={productdata?.pdata.image}
          qualitySelected={qualitySelected}
          show={t.visible}
          sizeSelected={sizeSelected}
          variantActive={variantActive}
          variantname={variantname}
        />
      ),
      { position: "top-right", id: "nc-product-notify", duration: 3000 }
    );
  };

  const renderVariants = () => {
    if (
      !productdata?.pdata.allattribute ||
      !productdata.pdata.allattribute.name ||
      !productdata.pdata.allattribute.name.length
    ) {
      return null;
    }

    const allattribute = productdata.pdata.allattribute;

    const handleVariantClick = (index: any, name: any) => {
      setVariantActive(index);
      setvariantname(name);
      setnewprice(Number(allattribute.price[index]));
      setQualitySelected(1);
    };

    return (
      <div>
        <label htmlFor="">
          <span className="text-sm font-medium">
            Attribute:
            <span className="ml-1 font-semibold">{variantname}</span>
          </span>
        </label>
        <div className="mt-2.5">
          <div className="grid grid-cols-4 gap-4">
            {allattribute.name.map((name: any, index: any) => {
              return (
                <div className="">
                  <div
                    key={index}
                    onClick={() => handleVariantClick(index, name)}
                    className={`relative flex-1  h-10 rounded-full border-2 cursor-pointer ${variantActive
                      ? "border-transparent"
                      : "border-primary-6000 dark:border-primary-500"
                      }`}
                  >
                    <div
                      className={`relative h-10 sm:h-11 rounded-2xl border flex items-center justify-center 
          text-sm sm:text-base uppercase font-semibold select-none overflow-hidden z-0 cursor-pointer 
          ${variantActive === index
                          ? "bg-primary-6000 border-primary-6000 text-white hover:bg-primary-6000"
                          : "border-transparent"
                        }`}
                    >
                      {name}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  const renderSizeList = () => {
    if (!allOfSizes || !size || !size.length) {
      return null;
    }
    return (
      <div>
        <div className="flex justify-between font-medium text-sm">
          <label htmlFor="">
            <span className="">
              Size:
              <span className="ml-1 font-semibold">{sizeSelected}</span>
            </span>
          </label>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="##"
            className="text-primary-6000 hover:text-primary-500"
          >
            See sizing chart
          </a>
        </div>
        <div className="grid grid-cols-5 sm:grid-cols-7 gap-2 mt-3">
          {Array.isArray(allOfSizes) &&
            allOfSizes.map((size, index) => {
              const isActive = size === sizeSelected;
              const sizeOutStock = !size.includes(size);
              return (
                <div
                  key={index}
                  className={`relative h-10 sm:h-11 rounded-2xl border flex items-center justify-center 
                text-sm sm:text-base uppercase font-semibold select-none overflow-hidden z-0 ${sizeOutStock
                      ? "text-opacity-20 dark:text-opacity-20 cursor-not-allowed"
                      : "cursor-pointer"
                    } ${isActive
                      ? "bg-primary-6000 border-primary-6000 text-white hover:bg-primary-6000"
                      : "border-slate-300 dark:border-slate-600 text-slate-900 dark:text-slate-200 hover:bg-neutral-50 dark:hover:bg-neutral-700"
                    }`}
                  onClick={() => {
                    if (sizeOutStock) {
                      return;
                    }
                    setSizeSelected(size);
                    setQualitySelected(1);
                  }}
                >
                  {size}
                </div>
              );
            })}
        </div>
      </div>
    );
  };

  const renderStatus = () => {
    if (!status) {
      return null;
    }
    const CLASSES =
      "absolute top-3 left-3 px-2.5 py-1.5 text-xs bg-white dark:bg-slate-900 nc-shadow-lg rounded-full flex items-center justify-center text-slate-700 text-slate-900 dark:text-slate-300";
    if (status === "New in") {
      return (
        <div className={CLASSES}>
          <SparklesIcon className="w-3.5 h-3.5" />
          <span className="ml-1 leading-none">{status}</span>
        </div>
      );
    }
    if (status === "50% Discount") {
      return (
        <div className={CLASSES}>
          <IconDiscount className="w-3.5 h-3.5" />
          <span className="ml-1 leading-none">{status}</span>
        </div>
      );
    }
    if (status === "Sold Out") {
      return (
        <div className={CLASSES}>
          <NoSymbolIcon className="w-3.5 h-3.5" />
          <span className="ml-1 leading-none">{status}</span>
        </div>
      );
    }
    if (status === "limited edition") {
      return (
        <div className={CLASSES}>
          <ClockIcon className="w-3.5 h-3.5" />
          <span className="ml-1 leading-none">{status}</span>
        </div>
      );
    }
    return null;
  };

  const renderSectionContent = () => {
    return (
      <div className="space-y-7 2xl:space-y-8">
        {/* ---------- 1 HEADING ----------  */}
        <div>
          <h2 className="text-2xl sm:text-3xl font-semibold">
            {productdata?.pdata.pname}
          </h2>

          <div className="flex items-center mt-5 space-x-4 sm:space-x-5">
            {/* <div className="flex text-xl font-semibold">$112.00</div> */}
            <Prices
              contentClass="py-1 px-2 md:py-1.5 md:px-3 text-lg font-semibold"
              price={
                newprice ? newprice : productdata?.pdata.price
              }
            />

            {/* <div className="h-7 border-l border-slate-300 dark:border-slate-700"></div> */}

            {/* <div className="flex items-center">
              <a
                href="#reviews"
                className="flex items-center text-sm font-medium"
              >
                <StarIcon className="w-5 h-5 pb-[1px] text-yellow-400" />
                <div className="ml-1.5 flex">
                  <span>4.9</span>
                  <span className="block mx-2">·</span>
                  <span className="text-slate-600 dark:text-slate-400 underline">
                    142 reviews
                  </span>
                </div>
              </a>
              <span className="hidden sm:block mx-2.5">·</span>
              <div className="hidden sm:flex items-center text-sm">
                <SparklesIcon className="w-3.5 h-3.5" />
                <span className="ml-1 leading-none">{status}</span>
              </div>
            </div> */}
          </div>
        </div>

        {/* ---------- 3 VARIANTS AND SIZE LIST ----------  */}
        <div className="">{renderVariants()}</div>
        <div className="">{renderSizeList()}</div>

        {/*  ---------- 4  QTY AND ADD TO CART BUTTON */}
        <div className="flex space-x-3.5">
          <div className="flex items-center justify-center bg-slate-100/70 dark:bg-slate-800/70 px-2 py-3 sm:p-3.5 rounded-full">
            {quantity && quantity > 0 ? (
              <NcInputNumber
                defaultValue={qualitySelected}
                onChange={setQualitySelected}
                maxValue={quantity === null ? undefined : quantity}
              />
            ) : (
              <>
                <ButtonPrimary
                  className="flex-1 flex-shrink-0"
                >
                  <NoSymbolIcon className="hidden sm:inline-block w-5 h-5 mb-0.5" />
                  <span className="ml-3">Sold Out</span>
                </ButtonPrimary>
              </>
            )}
          </div>
          {quantity && quantity > 0 ? (
            <ButtonPrimary
              className="flex-1 flex-shrink-0"
              onClick={notifyAddTocart}
            >
              <BagIcon className="hidden sm:inline-block w-5 h-5 mb-0.5" />
              <span className="ml-3">Add to cart</span>
            </ButtonPrimary>
          ) : ''}
        </div>

        {/*  */}
        <hr className=" 2xl:!my-10 border-slate-200 dark:border-slate-700"></hr>
        {/*  */}

        {/* ---------- 5 ----------  */}
        {/* <AccordionInfo data={productdata} /> */}

        {/* Accordion Info */}
        {productdata && (
          <section className="w-full rounded-2xl space-y-2.5">
            <AccordionInfo
              data={[
                {
                  name: "Description",
                  content: productdata.pdata.bdescription,
                },
                {
                  name: "Fabric + Care",
                  content: productdata.pdata.fabriccare,
                },

                {
                  name: "How it Fits",
                  content:productdata.pdata.howitfits,
                },
                {
                  name: "FAQ",
                  content: productdata.pdata.faq,
                },
              ]}
            />
          </section>
        )}
        {/* ---------- 6 ----------  */}
        <div className="hidden xl:block">
          <Policy />
        </div>
      </div>
    );
  };

  const renderDetailSection = () => {
    return (
      <div className="">
        <h2 className="text-2xl font-semibold">Product Details</h2>
        <div className="prose prose-sm sm:prose dark:prose-invert sm:max-w-4xl mt-7">
          <p>{productdata?.pdata.ddescription}</p>
        </div>
      </div>
    );
  };

  const renderReviews = () => {
    return (
      <div className="">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold flex items-center">
          <StarIcon className="w-7 h-7 mb-0.5" />
          {/* <span className="ml-1.5"> 4,87 · 142 Reviews</span> */}
        </h2>

        {/* comment */}
        <div className="mt-10">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-y-11 gap-x-28">
            <ReviewItem />
            <ReviewItem
              data={{
                comment: `I love the charcoal heavyweight hoodie. Still looks new after plenty of washes. 
                  If you’re unsure which hoodie to pick.`,
                date: "December 22, 2021",
                name: "Stiven Hokinhs",
                starPoint: 5,
              }}
            />
            <ReviewItem
              data={{
                comment: `The quality and sizing mentioned were accurate and really happy with the purchase. Such a cozy and comfortable hoodie. 
                Now that it’s colder, my husband wears his all the time. I wear hoodies all the time. `,
                date: "August 15, 2022",
                name: "Gropishta keo",
                starPoint: 5,
              }}
            />
            <ReviewItem
              data={{
                comment: `Before buying this, I didn't really know how I would tell a "high quality" sweatshirt, but after opening, I was very impressed. 
                The material is super soft and comfortable and the sweatshirt also has a good weight to it.`,
                date: "December 12, 2022",
                name: "Dahon Stiven",
                starPoint: 5,
              }}
            />
          </div>

          {/* <ButtonSecondary
            onClick={() => setIsOpenModalViewAllReviews(true)}
            className="mt-10 border border-slate-300 dark:border-slate-700 "
          >
            Show me all 142 reviews
          </ButtonSecondary> */}
        </div>
      </div>
    );
  };
  //test

  return (
    <div className={`nc-ProductDetailPage ${className}`}>
      {/* MAIn */}
      <main className="container mt-5 lg:mt-11">
        <div className="lg:flex">
          {/* CONTENT */}
          <div className="w-full lg:w-[55%] ">
            {/* HEADING */}
            <div className="relative">
              <div className="aspect-w-16 aspect-h-16">
                <img
                  src={productdata?.pdata.image}
                  className="w-full rounded-2xl object-cover"
                  alt="product detail 1"
                />
              </div>
              {renderStatus()}
              {/* META FAVORITES */}
              <LikeButton
                className="absolute right-3 top-3"
                productid={"1"}
                userid={"1"}
              />
            </div>
            <div className="grid grid-cols-2 gap-3 mt-3 sm:gap-6 sm:mt-6 xl:gap-8 xl:mt-8">
              {[0, 1].map((index) => {
                const imageUrl = productdata?.featuresimages?.[index]?.name
                  ? api_assets_url +
                  "uploads/" +
                  productdata.featuresimages[index].name
                  : null;

                return (
                  <div
                    key={index}
                    className="aspect-w-11 xl:aspect-w-10 2xl:aspect-w-11 aspect-h-16"
                  >
                    {imageUrl && (
                      <img
                        src={imageUrl}
                        className="w-full rounded-2xl object-cover"
                        alt={`product detail ${index + 1}`}
                      />
                    )}
                  </div>
                );
              })}
            </div>
          </div>

          {/* SIDEBAR */}
          <div className="w-full lg:w-[45%] pt-10 lg:pt-0 lg:pl-7 xl:pl-9 2xl:pl-10">
            {renderSectionContent()}
          </div>
        </div>

        {/* DETAIL AND REVIEW */}
        <div className="mt-12 sm:mt-16 space-y-10 sm:space-y-16">
          <div className="block xl:hidden">
            <Policy />
          </div>

          {renderDetailSection()}

          {/* <hr className="border-slate-200 dark:border-slate-700" /> */}

          {/* {renderReviews()} */}

          {/* <hr className="border-slate-200 dark:border-slate-700" /> */}

          {/* OTHER SECTION */}
          {/* <SectionSliderProductCard
            heading="Customers also purchased"
            subHeading=""
            headingFontClassName="text-2xl font-semibold"
            headingClassName="mb-10 text-neutral-900 dark:text-neutral-50"
          /> */}

          {/* SECTION */}
          <div className="pb-20 xl:pb-28 lg:pt-14">
            {/* <SectionPromo2 /> */}
          </div>
        </div>
      </main>

      {/* MODAL VIEW ALL REVIEW */}
      <ModalViewAllReviews
        show={isOpenModalViewAllReviews}
        onCloseModalViewAllReviews={() => setIsOpenModalViewAllReviews(false)}
      />
    </div>
  );
};

export default ProductDetailPage;
