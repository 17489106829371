import { useState, FormEvent } from "react";
import Label from "components/Label/Label";
import React from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import CommonLayout from "./CommonLayout";
import { api_url } from "common/Helpers";
import toast from "react-hot-toast";
import axios from "axios";
const AccountPass = () => {
  let userID = localStorage.getItem("userID");
  const [currentpassword, setcurrentpassword] = useState<string>('');
  const [newpassword, setnewpassword] = useState<string>('');
  const [confirmpassword, setconfirmpassword] = useState<string>('');
  const [loader, setloader] = useState<boolean>(false);
  const updatePassword = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!currentpassword || !newpassword || !confirmpassword) {
      return toast.error("All field required");
    }
    if (newpassword !== confirmpassword) {
      return toast.error("Password and confirm password should be same");
    }
    setloader(true);
    const formData = new FormData();
    // Append the values directly from HTMLInputElement
    formData.append("userID", userID || '');
    formData.append("current", currentpassword);
    formData.append("new", newpassword);
    formData.append("confirm", confirmpassword);
    axios
      .post(api_url + `updatepassword`, formData)
      .then(({ data }) => {
        if (data.success == true) {
          toast.success("Password updated successfully");
          emptypassField();
        } else {
          toast.error(data.message);
        }
        setloader(false);
      })
      .catch(({ response }) => {
        toast.error(response.data.message);
        setloader(false);
      });
  };
  const emptypassField = () => {
    setcurrentpassword("");
    setnewpassword("");
    setconfirmpassword("");
  }
  return (
    <div>
      <CommonLayout>
        <div className="space-y-10 sm:space-y-12">
          {/* HEADING */}
          <h2 className="text-2xl sm:text-3xl font-semibold">
            Update your password
          </h2>
          <form onSubmit={updatePassword}>
            <div className=" max-w-xl space-y-6">
              <div>
                <Label>Current password</Label>
                <Input value={currentpassword} type="password" className="mt-1.5" onChange={(e) => setcurrentpassword(e.target.value)} />
              </div>
              <div>
                <Label>New password</Label>
                <Input value={newpassword} type="password" className="mt-1.5" onChange={(e) => setnewpassword(e.target.value)} />
              </div>
              <div>
                <Label>Confirm password</Label>
                <Input value={confirmpassword} type="password" className="mt-1.5" onChange={(e) => setconfirmpassword(e.target.value)} />
              </div>
              <div className="pt-2">
                {loader ? (
                  <ButtonPrimary type="button">Please wait..</ButtonPrimary>
                ) : (
                  <ButtonPrimary type="submit">Update password</ButtonPrimary>
                )}
              </div>
            </div>
          </form>
        </div>
      </CommonLayout >
    </div >
  );
};

export default AccountPass;
