import { SocialType } from "shared/SocialsShare/SocialsShare";
import React, { FC, useEffect, useState } from "react";
import facebook from "images/socials/facebook.svg";
import twitter from "images/socials/twitter.svg";
import telegram from "images/socials/telegram.svg";
import youtube from "images/socials/youtube.svg";
import instagram from "images/socials/instagram.svg";
import WhatsappIcon from '../../images/whatsapp.png';
import { api_url, api_assets_url } from "common/Helpers";
import { Link } from "react-router-dom";

export interface SocialsList1Props {
  className?: string;
}
interface SocialItem {
  socialurl: string;
  socialsicon: string;
  name: string;
  status: string;
}
const socials: SocialType[] = [
  { name: "Facebook", icon: facebook, href: "#" },
  // { name: "Youtube", icon: youtube, href: "#" },
  // { name: "Telegram", icon: telegram, href: "#" },
  // { name: "Twitter", icon: twitter, href: "#" },
  { name: "Instagram", icon: instagram, href: "#" },

];

const SocialsList1: FC<SocialsList1Props> = ({ className = "space-y-3" }) => {
  const renderItem = (item: SocialType, index: number) => {
    return (
      <a
        href={item.href}
        className="flex items-center text-2xl text-neutral-700 hover:text-black dark:text-neutral-300 dark:hover:text-white leading-none space-x-2 group"
        key={index}
      >
        <div className="flex-shrink-0 w-5 ">
          <img src={item.icon} alt="" />
        </div>
        <span className="hidden lg:block text-sm">{item.name}</span>
      </a>
    );
  };
  const [SocialData, setSocialData] = useState<SocialItem[]>([]);
  const fetchData = () => {
    fetch(api_url + "getsocialmenus")
      .then((response) => response.json())
      .then((data) => {
        setSocialData(data);
      })
      .catch((error) => console.error('Error fetching data:', error));
  }
  useEffect(() => {
    fetchData();
  }, []);
  const [Wpnumber, setWpnumber] = useState<String>('');
  const fetchWpData = () => {
    fetch(api_url + "getcontact")
      .then((response) => response.json())
      .then((data) => {
        setWpnumber(data.wpphone);
      })
      .catch((error) => console.error('Error fetching data:', error));
  }
  useEffect(() => {
    fetchWpData();
  }, []);
  return (
    <div className={`nc-SocialsList1 ${className}`} data-nc-id="SocialsList1">
      <Link to={'https://wa.me/+91'+Wpnumber}><img src={WhatsappIcon} className="whatsapp-icon"></img></Link>
      {/* {socials.map(renderItem)} */}
      {SocialData.map((item, index) => (
        <>
          {item.status == "1" && (
            <>
              <a
                href={item.socialurl}
                className="flex items-center text-2xl text-neutral-700 hover:text-black dark:text-neutral-300 dark:hover:text-white leading-none space-x-2 group"
                key={index}
              >
                <div className="flex-shrink-0 w-5 ">
                  <img src={api_assets_url + 'uploads/' + item.socialsicon} alt="" />
                </div>
                <span className="hidden lg:block text-sm">{item.name}</span>
              </a>
            </>
          )}
        </>
      ))}
    </div>
  );
};

export default SocialsList1;
