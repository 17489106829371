export const actionTypes = {
  ADD_TO_CART: "ADD_TO_CART",
  REMOVE_FROM_CART: "REMOVE_FROM_CART",
  QTY_INCREMENT: "QTY_INCREMENT",
  QTY_DECREMENT: "QTY_DECREMENT",
  CAT_FILTER: "CAT_FILTER",
  CLEAR_CART: "CLEAR_CART",
  // Add other action types if needed
};

export const CartReducer = (state, action) => {
  switch (action.type) {
    case actionTypes.ADD_TO_CART:
      // Check if the product already exists in the cart with the same id, size, and allattribute
      const existingIndex = state.cart.findIndex(
        (item) =>
          item.id === action.payload.id &&
          item.size === action.payload.size &&
          item.allattribute === action.payload.allattribute
      );

      if (existingIndex >= 0) {
        // Product exists, set the quantity to the new value provided in the payload
        const cart = [...state.cart];
        cart[existingIndex] = {
          ...cart[existingIndex],
          qty: action.payload.qty, // Set quantity to payload qty
          price: action.payload.qty * cart[existingIndex].unitPrice, // Recalculate price based on new qty
        };
        return { ...state, cart };
      } else {
        // Product does not exist, add it to the cart with the specified qty
        return {
          ...state,
          cart: [...state.cart, { ...action.payload }],
        };
      }

      case actionTypes.REMOVE_FROM_CART:
        // Filter out the item that matches all three conditions: id, allattribute, and size
        return {
          ...state,
          cart: state.cart.filter(item =>
            !(item.id === action.payload.id &&
              item.allattribute === action.payload.allattribute &&
              item.size === action.payload.size)
          ),
        };

    case actionTypes.QTY_INCREMENT:
      return {
        ...state,
        cart: state.cart.map((item) =>
          item.id === action.payload.id && item.size === action.payload.size && item.allattribute === action.payload.allattribute
            ? { ...item, qty: item.qty + 1, price: (item.qty + 1) * item.unitPrice } // Adjust both quantity and price
            : item
        ),
      };

    case actionTypes.QTY_DECREMENT:
      return {
        ...state,
        cart: state.cart.map((item) =>
          item.id === action.payload.id && item.size === action.payload.size && item.allattribute === action.payload.allattribute && item.qty > 1
            ? { ...item, qty: item.qty - 1, price: (item.qty - 1) * item.unitPrice } // Adjust both quantity and price, ensuring qty is greater than 1
            : item
        ),
      };

    case actionTypes.CAT_FILTER:
      return {
        ...state,
        categoryFilter: action.payload,
      };

    case actionTypes.CLEAR_CART:
      return {
        ...state,
        cart: [],
      };

    default:
  }
  return state;
};
