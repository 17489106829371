import React, { useState, useEffect } from "react";
import CommonLayout2 from "./CommonLayout2";
import ModalQuickView from "../../components/DropReview";
import { api_url } from "common/Helpers";

type Product = {
  id: string;
  image: string;
  name: string;
  size: string;
  color: string;
  qty: string;
  price: number;
};

const AccountOrderview = () => {
  const [fetchedData, setFetchedData] = useState<Product[]>([]);
  const [loading, setLoading] = useState(true);
  const orderdata = localStorage.getItem("orderdata");
  const [parsedData, setparsedData] = useState([]);
  useEffect(() => {
  if (orderdata) {
    try {
      const parsedData = JSON.parse(orderdata);
      setparsedData(parsedData);
    } catch (error) {
      console.error("Error parsing order data:", error);
    }
  }
}, [orderdata]);

  useEffect(() => {
    if (parsedData && "orderid" in parsedData) {
      fetch(api_url + "vieworder/" + (parsedData.orderid as string))
        .then((response) => response.json())
        .then((data) => {
          console.log("Data fetched successfully:", data);
          setFetchedData(data.data);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
    setLoading(false);
  }, [parsedData]);

  const RenderProductItem: React.FC<{ product: Product; index: number }> = ({
    product,
    index,
  }) => {
    const [showModalQuickView, setShowModalQuickView] = React.useState(false);

    const { id, image, name, size, color, qty, price } = product;
    const [isExpanded, setIsExpanded] = useState(false);

    return (
      <div key={index} className="flex py-4 sm:py-7 last:pb-0 first:pt-0">
        <div className="h-24 w-16 sm:w-20 flex-shrink-0 overflow-hidden rounded-xl bg-slate-100">
          <img
            src={image}
            alt={name}
            className="h-full w-full object-cover object-center"
          />
        </div>
        <div className="ml-4 flex flex-1 flex-col">
          <div>
            <div className="flex justify-between">
              <div>
                <h3 className="text-base font-medium line-clamp-1">{name}</h3>
                <p className="mt-1 text-sm text-slate-500 dark:text-slate-400">
                  <span>{color}</span>
                  <span className="mx-2 border-l border-slate-200 dark:border-slate-700 h-4"></span>
                  <span>
                    {size}, Qty : {qty}
                  </span>
                </p>
              </div>
              <span> ₹ {price}</span>

              <ModalQuickView
                show={showModalQuickView}
                productdata={product}
                onCloseModalQuickView={() => setShowModalQuickView(false)}
              />
            </div>
          </div>
        </div>
        <hr className="mt-0 border-slate-200 dark:border-slate-700"></hr>
      </div>
    );
  };

  const renderOrder = () => {
    if (fetchedData.length === 0) {
      return <p>No orders found.</p>;
    } else {
      return (
        <div className="border border-slate-200 dark:border-slate-700 rounded-lg overflow-hidden z-0">
          <div className="border-t border-slate-200 dark:border-slate-700 p-2 sm:p-0 mt-2 divide-y divide-y-slate-200 dark:divide-slate-700">
            {fetchedData.map((product: Product, productIndex: number) => (
              <RenderProductItem
                key={productIndex}
                product={product}
                index={productIndex}
              />
            ))}
          </div>
        </div>
      );
    }
  };

  return (
    <div>
      <CommonLayout2 orderdata={parsedData} productdata={fetchedData}>
        <div className="space-y-10 sm:space-y-0">{renderOrder()}</div>
      </CommonLayout2>
    </div>
  );
};

export default AccountOrderview;
