import React, { useEffect, useState } from "react";

import NavigationItem from "./NavigationItem";
import { NavItemType } from "shared/Navigation/NavigationItem";
import ncNanoId from "utils/ncNanoId";
import { api_url } from "common/Helpers";


function Navigation() {
  const apiUrl = api_url + "categorylist";
  const [category, setCategories] = useState<{ name: string, id: string }[]>([]);
  const [mynav, setMynav] = useState<NavItemType[]>([
    {
      id: ncNanoId(),
      href: "/",
      name: "Categories",
      type: "dropdown",
      children: [],
    },
    {
      id: ncNanoId(),
      href: "/page-search",
      name: "Shop",
    },
    {
      id: ncNanoId(),
      href: "/coupon",
      name: "Coupon",
    },
    {
      id: ncNanoId(),
      href: "/track-order",
      name: "Track Order",
    },
    {
      id: ncNanoId(),
      href: "/contact",
      name: "Contact us",
    },
    
  ]);

  useEffect(() => {
    // Fetch data from the API
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        // Update the categories state with the fetched data
        setCategories(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []); // Empty dependency array

  // Update the "Category" dropdown in mynav with the fetched categories
  useEffect(() => {
    const updatedMynav = mynav.map((item) => {
      if (item.name === "Categories") {
        return {
          ...item,
          children: category.map((categories) => ({
            id: ncNanoId(),
            name: categories.name,
            href: "/page-collection/"+categories.id,
          })),
        };
      }
      return item;
    });

    setMynav(updatedMynav);
  }, [category]);

  return (
    <ul className="nc-Navigation flex items-center">
      {mynav.map((item) => (
        <NavigationItem key={item.id} menuItem={item} />
      ))}
    </ul>
  );
}

export default Navigation;
