import React, { FC } from 'react';
import { Helmet } from 'react-helmet-async';

export interface TermsAndConditionsProps {
  className?: string;
}

const TermsAndConditions: FC<TermsAndConditionsProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-TermsAndConditions overflow-hidden relative ${className}`}
      data-nc-id="TermsAndConditions"
    >
      <Helmet>
        <title>Terms and Conditions || AK Beats Clothing</title>
      </Helmet>

      <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
        <h1 className="text-2xl lg:text-4xl font-bold mb-4">
          Terms and Conditions
        </h1>

        <p className="text-base lg:text-lg leading-loose mb-4">
          Last Updated: 28-12-2023
        </p>

        <section>
          <h2 className="text-xl lg:text-2xl font-bold mt-1">1. Introduction</h2>
          <p className="text-base lg:text-lg leading-loose">
            Welcome to AK Beats Clothing. By accessing our website and using our services, you agree to be bound by the following terms and conditions.
          </p>
        </section>

        <section>
          <h2 className="text-xl lg:text-2xl font-bold mt-1">2. Use of Website</h2>
          <p className="text-base lg:text-lg leading-loose">
            This website is intended for personal, non-commercial use. Users must not misuse the website in any way that violates local, state, or federal laws.
          </p>
        </section>

        <section>
          <h2 className="text-xl lg:text-2xl font-bold mt-1">3. Product Information</h2>
          <p className="text-base lg:text-lg leading-loose">
            We strive to ensure that all product information on our website is accurate, however, discrepancies may occur. We reserve the right to correct any errors or inaccuracies.
          </p>
        </section>

        <section>
          <h2 className="text-xl lg:text-2xl font-bold mt-1">4. Pricing and Payment</h2>
          <p className="text-base lg:text-lg leading-loose">
            Prices for our products are subject to change without notice. We accept various forms of payment, detailed on our website.
          </p>
        </section>

        <section>
          <h2 className="text-xl lg:text-2xl font-bold mt-1">5. Shipping and Delivery</h2>
          <p className="text-base lg:text-lg leading-loose">
            Details about shipping methods, times, and costs are available on our website.
          </p>
        </section>

        <section>
          <h2 className="text-xl lg:text-2xl font-bold mt-1">6. Returns and Refunds</h2>
          <p className="text-base lg:text-lg leading-loose">
            We accept returns under certain conditions, outlined in our Return Policy. Eligible products may be returned for a refund or exchange.
          </p>
        </section>

        <section>
          <h2 className="text-xl lg:text-2xl font-bold mt-1">7. Privacy Policy</h2>
          <p className="text-base lg:text-lg leading-loose">
            Our Privacy Policy, which outlines how we handle your personal information, forms part of these Terms and Conditions.
          </p>
        </section>

        <section>
          <h2 className="text-xl lg:text-2xl font-bold mt-1">8. Intellectual Property</h2>
          <p className="text-base lg:text-lg leading-loose">
            All content on this site, including text, graphics, logos, images, and software, is the property of AK Beats Clothing.
          </p>
        </section>

        <section>
          <h2 className="text-xl lg:text-2xl font-bold mt-1">9. Limitation of Liability</h2>
          <p className="text-base lg:text-lg leading-loose">
            AK Beats Clothing will not be liable for any indirect, incidental, special, or consequential damages arising from the use of this website.
          </p>
        </section>

        <section>
          <h2 className="text-xl lg:text-2xl font-bold mt-1">10. Changes to Terms and Conditions</h2>
          <p className="text-base lg:text-lg leading-loose">
            We reserve the right to modify these terms and conditions at any time. Continued use of the website after such changes will constitute acceptance of the new terms and conditions.
          </p>
        </section>

        <section>
          <h2 className="text-xl lg:text-2xl font-bold mt-1">11. Governing Law</h2>
          <p className="text-base lg:text-lg leading-loose">
            These terms and conditions are governed by the laws of the jurisdiction where AK Beats Clothing is based.
          </p>
        </section>

        <section>
          <h2 className="text-xl lg:text-2xl font-bold mt-1">12. Contact Us</h2>
          <p className="text-base lg:text-lg leading-loose">
            For any questions or concerns about these terms and conditions, please email us at <a href="mailto:akbeats.2021@gmail.com">akbeats.2021@gmail.com</a>.
          </p>
        </section>

        <section>
          <h2 className="text-xl lg:text-2xl font-bold mt-1">13. Acknowledgment</h2>
          <p className="text-base lg:text-lg leading-loose">
            By using this website, you acknowledge that you have read, understood, and agree to be bound by these Terms and Conditions.
          </p>
        </section>
      </div>
    </div>
  );
};

export default TermsAndConditions;
