import React, { FC,useState,useEffect } from "react";
import facebookSvg from "images/Facebook.svg";
import twitterSvg from "images/Twitter.svg";
import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet-async";
import Input from "shared/Input/Input";
import axios from 'axios';
import { Link,useNavigate } from "react-router-dom";
import {api_url, app_name, app_url} from '../../common/Helpers';
import ButtonPrimary from "shared/Button/ButtonPrimary";
import toast from "react-hot-toast";
import { auth } from "../../firebase.config";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
const loginSocials = [
  {
    name: "Continue with Facebook",
    href: "#",
    icon: facebookSvg,
  },
  {
    name: "Continue with Twitter",
    href: "#",
    icon: twitterSvg,
  },
  {
    name: "Continue with Google",
    href: "#",
    icon: googleSvg,
  },
];

const PageLogin = ({ className = "" }) => {
  const [number, setnumber] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLogin, setisLogin] = useState(false);
  const [logindata, setLogindata] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [otp, setOtp] = useState("");
  const navigate = useNavigate();
  function onCaptchVerify() {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "invisible",
          callback: (response) => {
            onSignup();
          },
          "expired-callback": () => {},
        },
        auth
      );
    }
  }
  const handleSubmit = () => {
    const formDataObj = new FormData();
    formDataObj.append('number', number);
    axios
    .post(api_url + 'login', formDataObj)
    .then((response) => {
      if (response.data.status !== 0) {
        setLogindata(response.data.userdata);
        onCaptchVerify();
        setIsLoading(true);
        const appVerifier = window.recaptchaVerifier;
        const formatPh = "+91"+number;
        signInWithPhoneNumber(auth, formatPh, appVerifier)
          .then((confirmationResult) => {
            window.confirmationResult = confirmationResult;
            setIsLoading(false);
            setIsValid(true);
            toast.success('OTP sent successfully!', {
              position: 'top-right',
            });
          })
          .catch((error) => {
            console.log(error);
            setIsLoading(false);
          }); 
      }else{
        toast.error('Phone number not found!', {
          position: 'top-right',
        });
      }
    })
    .catch((error) => {
      console.error(error);
      toast.error('An error occurred during sign-up');
    })
  };
  function onSignup() {
    if (!number || !/^\d{10}$/.test(number)) {
      toast.error('Number must be 10 digits', {
        position: 'top-right',
      });
      return false;
    }
    handleSubmit();
  }
  function onOTPVerify() {
    if (!otp || !/^\d{6}$/.test(otp)) {
      toast.error('OTP must be 6 digits', {
        position: 'top-right',
      });
      return false;
    }
    setIsLoading(true);
    window.confirmationResult
      .confirm(otp)
      .then(async (res) => {
        localStorage.setItem('userID',logindata.id)
        localStorage.setItem('userName',logindata.name)
        localStorage.setItem('userNumber',logindata.number)
        localStorage.setItem('userEmail',logindata.email)
        localStorage.setItem('userPic',logindata.profilepic)
        setIsLoading(false);
        // navigate(app_url);
        toast.success('Login successful', {
          position: 'top-right',
        });
        navigate('/');
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }
  useEffect(()=>{
    reloadonetime()
},[])
const reloadonetime = () =>{
  if(!window.location.hash) {
    window.location = window.location+'##';
    window.location.reload();
  }
}
const handlePhoneChange = (event) => {
  const inputValue = event.target.value;

  // Remove any non-numeric characters from the input
  const numericValue = inputValue.replace(/\D/g, '');

  // Limit the input to 10 characters
  const limitedValue = numericValue.slice(0, 10);

  // Update the phone state with the cleaned and limited value
  setnumber(limitedValue);
};
  return (
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <Helmet>
        <title>Login || {app_name}</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Login
        </h2>
        <div className="max-w-md mx-auto space-y-6">
          <div className="grid gap-3" style={{ display: 'none' }}>
            {loginSocials.map((item, index) => (
              <a
                key={index}
                href={item.href}
                className="flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
              >
                <img
                  className="flex-shrink-0"
                  src={item.icon}
                  alt={item.name}
                />
                <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
                  {item.name}
                </h3>
              </a>
            ))}
          </div>
          {/* OR */}
          <div id="recaptcha-container"></div>
          <div className="relative text-center" style={{ display: 'none' }}>
            <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
              OR
            </span>
            <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
          </div>
          {/* FORM */}
          <form className="grid grid-cols-1 gap-6" action="#" method="post">
          {!isValid ? (
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Mobile number
              </span>
              <Input
                type="text"
                onChange={handlePhoneChange}     
                pattern="[0-9]{10}"
                maxLength={10}
                value={number}
                placeholder="Enter Your Number"
                className="mt-1"
                style={{
                  WebkitAppearance: 'none',
                  appearance: 'none',
                  margin: 0,
                }}
              />

            </label>
            ) : (
              <label className="block">
                <span className="text-neutral-800 dark:text-neutral-200">Verification Code</span>
                <Input
                  type="text"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                  placeholder="Enter OTP"
                  className="mt-1"
                />
              </label>
            )}
            <label className="block" style={{ display: 'none' }}>
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Password
                <Link to="/forgot-pass" className="text-sm text-green-600">
                  Forgot password?
                </Link>
              </span>
              <Input type="password" className="mt-1" />
            </label>
            {!isValid ? (
      <>
              {isLoading ? (
          <p></p>
        ) : (
          <ButtonPrimary
          type="button"
          onClick={onSignup}
          className={`btn-primary ${isLoading ? 'hidden' : 'inline-block mt-4'}`}
        >
          Continue
        </ButtonPrimary>
        
        
        )}
      </>
    ): (
      <>
        <ButtonPrimary
          type="button"
          onClick={onOTPVerify}
          className={`btn-primary ${isLoading ? 'hidden' : 'inline-block mt-4'}`}
        >
          Continue
        </ButtonPrimary>
      </>
    )}
    {isLoading && (
        <div className="flex justify-center items-center mt-4">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
        </div>
      )}
          </form>

          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            New user? {` `}
            <Link className="text-green-600" to="/signup">
              Create an account
            </Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageLogin;
