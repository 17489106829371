import React from "react";
import MyRouter from "routers/index";
import { Helmet, HelmetProvider } from "react-helmet-async";
import CartProvider from './context/Context'; // Import your Context component
import { app_name } from "common/Helpers";

function App() {
  return (
      <HelmetProvider>
        <Helmet>
          <title>{app_name}</title>
          <meta
            name="description"
            content={app_name}
          />
        </Helmet>
        <CartProvider> {/* Wrap your app with the Context component */}
        {/* MAIN APP */}
        <div className="bg-white text-base dark:bg-slate-900 text-slate-900 dark:text-slate-200">
          <MyRouter />
        </div>
        </CartProvider>
      </HelmetProvider>
  );
}

export default App;
