import React, { FC } from "react";
import { NavLink } from "react-router-dom";
import { Row, Col,Button } from "reactstrap";
import ButtonSecondary from "shared/Button/ButtonSecondary";

interface Product {
  id: string;
  image: string;
  name: string;
  size: string;
  color: string;
  qty: string;
  price: number;
}

interface CommonLayoutProps {
  children?: React.ReactNode;
  orderdata: any;
  productdata: Product[];
}

const CommonLayout: FC<CommonLayoutProps> = ({
  children,
  orderdata,
  productdata,
}) => {
  
  return (
    <div className="nc-CommonLayoutProps container">
      <div className="mt-14 sm:mt-20"> 
        <div className="max-w-4xl mx-auto">
          <div className="max-w-2xl">
            <h2 className="text-3xl xl:text-4xl font-semibold">
              Order Details
            </h2>
            
            <div className="mt-4">
                <div className="flex">
                  <span className="text-slate-900 dark:text-slate-200 font-semibold">
                  Order ID :
                  </span>
                  <span className="ml-2">{String(orderdata.orderid)}</span>{" "}
                 
                </div>
                
                <div className="mt-3 sm:mt-0 d-flex justify-content-end">
    
                </div>


                <div className="flex">
                  <span className="text-slate-900 dark:text-slate-200 font-semibold">
                  Date :
                  </span>
                  <span className="ml-2">{String(orderdata.date)}</span>{" "}
                </div>
            </div>
          </div>
          <hr className="mt-10 border-slate-200 dark:border-slate-700" />
        </div>

        <div className="max-w-4xl mx-auto sm:pt-26 pb-15 lg:pb-32">
          {children}
        </div>
        <div className="max-w-4xl mx-auto">
          <hr className="mb-5 border-slate-200 dark:border-slate-700" />
          <div>
            <h1 className="text-right">
              <strong>Order summary</strong>
            </h1>
            <Row className="fullPadding">
              <Col xs={6} className="text-right">
                <div className="d-flex">
                  <p className="mb-1">
                  Total <span>INR {orderdata.totalAmount}</span>
                  </p>                  
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <div className="max-w-4xl mx-auto pt-2">
          <hr className="mb-5 border-slate-200 dark:border-slate-700" />
          <div>
            <h1 className="text-right">
              <strong>Delivery Address</strong>
            </h1>
            <Row className="fullPadding">
              <Col xs={6} className="text-right">
                <div className="d-flex">
                  <p className="mb-1 mt-2">{orderdata.address}</p>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommonLayout;
