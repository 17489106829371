import React, { useEffect, useId, useState } from "react";
import axios from 'axios';
import Heading from "./Heading/Heading";
import img1 from "images/collections/1.png";
import img2 from "images/collections/5.png";
import img3 from "images/collections/4.png";
import img4 from "images/collections/3.png";

import CardCategory3, {
  CardCategory3Props,
} from "./CardCategories/CardCategory3";
import Glide from "@glidejs/glide";
let mycategory = [
  {
    "name": "Explore new",
    "featuredImage": "https://essane.in/mcfashion/storage/app/public/uploads/1694691808.png",
    "color": "bg-yellow-50",
    "salecollection": "Explore new"
  },
  {
      "name": "Digital gift cards",
     
      "featuredImage": "https://essane.in/mcfashion/storage/app/public/uploads/1694691808.png",
      "color": "bg-red-50"
  },
  {
      "name": "Sale collection",
      "desc": "Up to <br /> 80% off retail",
      "featuredImage": "https://essane.in/mcfashion/storage/app/public/uploads/1694691808.png",
      "color": "bg-blue-50"
  },
  {
      "name": "Sale collection",
      "desc": "Up to <br /> 80% off retail",
      "featuredImage": "https://essane.in/mcfashion/storage/app/public/uploads/1694691808.png",
      "color": "bg-green-50"
  }
]
export const CATS_DISCOVER: CardCategory3Props[] = mycategory;
interface DiscoverMoreSliderProps {
  apiData: CardCategory3Props[]; // Provide the correct type here
}

const DiscoverMoreSlider: React.FC<DiscoverMoreSliderProps> = ({ apiData }) => {
  const id = useId();
  const UNIQUE_CLASS = "glidejs" + id.replace(/:/g, "_");
  useEffect(() => {
    // @ts-ignore
    const OPTIONS: Glide.Options = {
      perView: 2.8,
      gap: 32,
      bound: true,
      breakpoints: {
        1280: {
          gap: 28,
          perView: 2.5,
        },
        1279: {
          gap: 20,
          perView: 2.15,
        },
        1023: {
          gap: 20,
          perView: 1.6,
        },
        768: {
          gap: 20,
          perView: 1.2,
        },
        500: {
          gap: 20,
          perView: 1,
        },
      },
      
    };

    let slider = new Glide(`.${UNIQUE_CLASS}`, OPTIONS);
    slider.mount();
    return () => {
      slider.destroy();
    };
  }, [UNIQUE_CLASS]);
  return (
    <div className={`nc-DiscoverMoreSlider nc-p-l-container ${UNIQUE_CLASS} `}>
      <Heading
        className="mb-12 lg:mb-14 text-neutral-900 dark:text-neutral-50 nc-p-r-container "
        desc=""
        rightDescText=""
        hasNextPrev
      >
      Begin at AK 
      </Heading>
      <div className="" data-glide-el="track">
        <ul className="glide__slides">
        {apiData.map((item, index) => (
          <li key={index} className={`glide__slide`}>
            <CardCategory3
              id={item.id}
              desc={item.desc}
              featuredImage={item.featuredImage}
              color={item.color}
               name={item.name}
              
            />
          </li>
))}

        </ul>
      </div>
      
    </div>
  );
};

export default DiscoverMoreSlider;
