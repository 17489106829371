import React, { createContext, useContext, useReducer } from "react";
import { CartReducer, actionTypes } from "./Reducers"; // Import actionTypes from reducers.js

// Create a context
const CartContext = createContext();

// Define the initial state inside the useReducer hook
const initialState = {
  prod: [], // Initialize with an empty array
  cart: [],
  pleaseWait: false // Set to false since you're not making API calls
};

// Define the Context component
const Context = ({ children }) => {
  // Use the cartReducer function to create your reducer
  const [state, dispatch] = useReducer(CartReducer, initialState);
  return (
    <CartContext.Provider value={{ state, dispatch }}>
      {children}
    </CartContext.Provider>
  );
};

// Create custom hooks for accessing the CartContext
export const useCart = () => {
  return useContext(CartContext);
};

export default Context;
