import React, { FC } from "react";
import { Helmet } from "react-helmet-async";

export interface PrivacyPolicyProps {
  className?: string;
}

const PrivacyPolicy: FC<PrivacyPolicyProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-PrivacyPolicy overflow-hidden relative ${className}`}
      data-nc-id="PrivacyPolicy"
    >
      <Helmet>
        <title>Privacy Policy || AKBeats Clothing</title>
      </Helmet>

      <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
        <h1 className="text-2xl lg:text-4xl font-bold mb-4">
          Privacy Policy
        </h1>
        <p className="text-lg lg:text-xl leading-loose">
          Introduction
          Welcome to AKBeats Clothing, your trusted destination for e-commerce shopping! At AKBeats Clothing, we are dedicated to safeguarding your privacy and ensuring the security of your personal information. This Privacy Policy explains how we collect, use, disclose, and protect your data when you use our e-commerce platform, including our website and mobile application (referred to as the "Service"). By accessing or using our Service, you consent to the practices described in this Privacy Policy.
        </p>

        <h1 className="text-xl lg:text-2xl font-bold mt-1">
          Information We Collect
        </h1>
        <p className="text-base lg:text-lg leading-loose">
          Personal Information
          Name: We collect your name to personalize your experience on our e-commerce platform.
          Email Address: We collect your email address for communication, order notifications, and customer support.
          Phone Number: We may collect your phone number for order verification and customer support.
          Order and Transaction Information
          We collect and process information related to the products or services you purchase through our e-commerce platform, including:
        </p>
        <ul className="list-disc pl-8">
          <li>Billing and shipping address</li>
          <li>Payment information (e.g., credit card details)</li>
          <li>Order history</li>
          <li>Customer support and order inquiries</li>
          <li>Usage Data</li>
          <li>We may collect information about how you interact with our Service, such as your IP address, browser type, the pages you visit, and the date and time of your visits.</li>
        </ul>

       <p>
       Cookies and Similar Technologies
We use cookies and similar technologies to collect information about your browsing habits and preferences. You can manage your cookie preferences by adjusting your browser settings.

How We Use Your Information
We use the information we collect for the following purposes:

To provide and maintain the e-commerce Service
To process and fulfill your orders
To notify you about order updates and changes to our Service
To provide customer support
To monitor and analyze the use of the Service
To detect, prevent, and address technical issues
Third-Party Payment Processors
To process payments for orders, we may use third-party payment processors. Please note that we do not store your payment information; it is collected and processed by these third-party providers. We encourage you to review their privacy policies and terms of service to understand how they handle your data.

Sharing Your Information
We may share your personal information with third parties under the following circumstances:

With your consent
To comply with legal obligations
To protect and defend our rights and property
To prevent or investigate possible wrongdoing in connection with the Service
To protect the personal safety of users or the public
Data Security
We take the security of your personal information and payment data seriously and employ industry-standard security measures to protect it. However, please be aware that no method of transmission over the internet or electronic storage is entirely secure, and we cannot guarantee the absolute security of your data.

Your Choices
You have the right to access, update, or delete your personal information. You can also opt-out of marketing communications by following the unsubscribe link in our emails.

Product and Service Reviews
If you leave a product or service review on our website, your review may be publicly visible, including your name, the content of your review, and any images or videos you submit.

User Accounts
If you create an account on our e-commerce platform, we will collect and store the information you provide to us, such as your username, password, and account preferences. You are responsible for maintaining the security of your account information.

Retention of Information
We will retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law.

Children's Privacy
AKBeats Clothing is not intended for children under the age of 13. We do not knowingly collect personal information from children under 13. If you believe that we have collected personal information from a child under 13, please contact us, and we will take steps to remove the information.

Changes to This Privacy Policy
We may update this Privacy Policy from time to time. The date of the latest revision will be indicated at the top of this page. We encourage you to review this policy regularly to stay informed about how we protect your data.

Contact Us
If you have any questions or concerns about this Privacy Policy, our data practices, or any e-commerce-related inquiries, please contact us at support@akbeatsclothing.com.
       </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
