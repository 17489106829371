import React, { FC, Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import { api_url } from "common/Helpers";

export interface ModalQuickViewProps {
  show: boolean;
  productdata: any;
  onCloseModalQuickView: () => void;
  index?: number; // Make the index prop optional
}


const ModalQuickView: FC<ModalQuickViewProps> = ({ productdata, show, onCloseModalQuickView, index }) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [reviewSubmitted, setReviewSubmitted] = useState(false);
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");

  const handleRatingChange = (value: number) => {
    setRating(value);
  };

  const handleCommentChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setComment(event.target.value);
  };
  const handleSubmitReview = () => {
    // Data to send in the request body
    const data = {
      // Add your review data here
      // For example, if you have a review text and a rating:
      reviewText: comment,
      rating: rating,
      userid: localStorage.getItem('userID'),
    };
  
    // URL where you want to send the POST request
    const apiUrl = api_url+'addreview'; // Replace with your API endpoint
  
    fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json', // Set the content type to JSON
      },
      body: JSON.stringify(data), // Convert the data object to a JSON string
    })
      .then(response => {
        if (response.ok) {
          return response.json(); // Parse the response JSON if successful
        } else {
          throw new Error('Failed to submit the review');
        }
      })
      .then(data => {
        // Handle the response data (if any) here
        console.log('Review submitted successfully', data);
        setReviewSubmitted(true);
      })
      .catch(error => {
        // Handle any errors that occurred during the request
        console.error('Error:', error);
      });
  };
  

  return (
    <Transition appear show={show} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-50" onClose={onCloseModalQuickView}>
        <div className="flex items-stretch md:items-center justify-center h-full text-center md:px-4">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black/40 dark:bg-black/70" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="inline-block align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="relative inline-flex xl:py-8 w-full max-w-2xl max-h-full">
              <div className="flex-1 flex overflow-hidden max-h-full p-8 w-full text-left align-middle transition-all transform lg:rounded-2xl bg-white dark-bg-color dark-text-color shadow-xl">
                <span className="absolute right-3 top-3 z-50">
                  <ButtonClose onClick={onCloseModalQuickView} />
                </span>

                <div className="flex-1 overflow-y-auto rounded-xl hiddenScrollbar">
                  {/* review system */}
                  <div className="row">
                      <div className="col-6">
                      <div className={`!visible ${isExpanded ? "" : "hidden"}`} id={`collapseExample-${index}`} data-te-collapse-item>
                        {reviewSubmitted ? (
                          <div className="success-message text-center" style={{fontWeight:700, fontSize:'25px'}}>
                            Thank you for your review!
                          </div>
                        ) : (
                          <div className={`review-form ${isExpanded ? "open" : ""}`}>
                            <h1 className="text-center" style={{backgroundColor: 'antiquewhite',fontWeight:600, padding:'10px', borderRadius:'11px'}}>Review and Rating</h1>
                            <h2 className="mt-4 text-center">How was your experience about our product?</h2>
                            <div className="rating-component">
                              <div className="stars-box">
                                {[1, 2, 3, 4, 5].map((value) => (
                                  <i
                                    key={value}
                                    className={`star fa fa-star${rating >= value ? " active" : ""}`}
                                    title={`${value} star`}
                                    onClick={() => handleRatingChange(value)}
                                  />
                                ))}
                              </div>
                              <div className="starrate text-center">
                                <p>Your Rating: {rating} stars</p>
                              </div>
                            </div>
                            <div className="tags-container" data-tag-set={1}>
                              <div className="question-tag text-center mb-2">Why was your experience so bad?</div>
                            </div>
                            <div className="tags-box text-center mb-4">
                              <textarea 
                              cols={35}
                              rows={3}
                              style={{border:'1px dashed'}}
                                className="tag form-control text-center"
                                name="comment"
                                placeholder="Please enter your review"
                                value={comment}
                                onChange={handleCommentChange}
                              />
                            </div>
                            <div className="button-box text-center">
                              <button className="button-hov" style={{background:'antiquewhite', fontWeight:500, borderRadius:'8px',padding:'9px'}} onClick={handleSubmitReview}>Add Review</button>
                            </div>
                          </div>
                        )}
                      </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ModalQuickView;
