import React, { FC ,useState,useEffect} from "react";
import { Helmet } from "react-helmet-async";
import Pagination from "shared/Pagination/Pagination";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import SectionSliderCollections from "components/SectionSliderLargeProduct";
import SectionPromo1 from "components/SectionPromo1";
import ProductCard from "components/ProductCard";
import TabFilters from "./TabFilters";
import { PRODUCTS } from "data/data";
import axios from 'axios';
import {useParams } from "react-router-dom";
import { useCart } from "../context/Context"; // Import your custom hook and CartState
import {api_url, app_name} from 'common/Helpers';
import { clearLine } from "readline";
export interface PageCollectionProps {
  className?: string;
}
// export interface TabFilterSectionProps {
//   onChangeRangePrices?: (prices: number[]) => void;
//   onCategoryChangeList?: (categories: string[]) => void;
//   onsizechangelist?: (sizes: string[]) => void;
// }
interface Product {
  size?: string[];
  price: number;
  category: string;
}

const PageCollection: FC<PageCollectionProps> = ({ className = "" }) => {
  const [numToDisplay, setNumToDisplay] = useState(8);
  const [products, setProducts] = useState([]);
  const [alldisplayedProducts, setalldisplayedProducts] = useState([]);
  // const [rangePrices, setRangePrices] = useState([100, 5000]);
  const [isLoading, setIsLoading] = useState(false);

  const [DisplayedProducts, setDisplayedProducts] = useState([]);

  const { state, dispatch } = useCart(); // Use your custom hook to access context
  const {catid}= useParams();
  useEffect(() => {
    // Define the API URL
    const apiUrl = api_url+'getproductsbycat/'+ catid;

    // Make the API request using Axios
    axios.get(apiUrl)
      .then((response) => {
        // Handle the successful response here
        setProducts(response.data);
        
      })
      .catch((error) => {
        // Handle errors here
        console.error('Error fetching data:', error);
      });
  }, [catid]);
  // const onsizechangelist = (sizes: string[]) => {
  //   console.log(sizes)
  //   };
  // const handleonCategoryChangeList = (categoryIds: string[]) => {
  // console.log(categoryIds)
  
  // };
  // function onChangeRangePricesLocal(rangePrices: number[]) {
  //  console.log(rangePrices)
  // }
  useEffect(() => {
    setDisplayedProducts(products.slice(0, numToDisplay));
    setalldisplayedProducts(products.slice(0, numToDisplay));
  }, [products, numToDisplay]);
  const [rangePrices, setRangePrices] = useState<number[]>([]);
  const onChangeRangePricesLocal = (pricerange: number[]) => {
    setRangePrices(pricerange);
  };
  const onsizechangelist = (sizes: string[]) => {
    const filteredProducts = alldisplayedProducts.filter((item: Product) => {
      const productSizes = item.size || [];
      return sizes.every((selectedSize) => productSizes.includes(selectedSize));
    });
    setDisplayedProducts(filteredProducts);
  };
  const showMore = () => {
    setIsLoading(true);
    setTimeout(() => {
      setNumToDisplay(numToDisplay + 8);

      const apiUrl = api_url + "getproductsbycat";

      axios
        .get(apiUrl)
        .then((response) => {
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }, 1000);
  };
  // const handleonCategoryChangeList = (catnames: string[]) => {
  //   const filteredProducts = alldisplayedProducts.filter((item: Product) => {
  //     const productPrice = item.price;
  //     const productCategory = item.category;
  //     const isPriceInRange =
  //       productPrice >= rangePrices[0] && productPrice <= rangePrices[1];
  //     const showAllCategories = catnames.includes("All Categories");
  
  //     if (showAllCategories) {
  //       return isPriceInRange;
  //     }
  
  //     const isCategoryMatched = catnames.includes(productCategory);
  //     return isPriceInRange && isCategoryMatched;
  //   });
  
  //   setDisplayedProducts(filteredProducts);
  // };
  useEffect(() => {
    const filteredProducts = alldisplayedProducts.filter((item: Product) => {
      const productPrice = item.price;
      // Other checks
      return productPrice !== undefined && productPrice >= rangePrices[0] && productPrice <= rangePrices[1];
    });

    setDisplayedProducts(filteredProducts);
  }, [rangePrices, alldisplayedProducts]);
  return (
    <div
      className={`nc-PageCollection ${className}`}
      data-nc-id="PageCollection"
    >
      <Helmet>
        <title>Collection || {app_name}</title>
      </Helmet>

      <div className="container py-16 lg:pb-28 lg:pt-20 space-y-16 sm:space-y-20 lg:space-y-28">
        <div className="space-y-10 lg:space-y-14">
          {/* HEADING */}
          <div className="max-w-screen-sm">
            <h2 className="block text-2xl sm:text-3xl lg:text-4xl font-semibold">
              Man collection
            </h2>
            <span className="block mt-4 text-neutral-500 dark:text-neutral-400 text-sm sm:text-base">
              We not only help you design exceptional products, but also make it
              easy for you to share your designs with more like-minded people.
            </span>
          </div>

          <hr className="border-slate-200 dark:border-slate-700" />
          <main>
            {/* TABS FILTER */}
            <TabFilters 
             rangePrices={rangePrices}
             onChangeRangePrices={onChangeRangePricesLocal}
            //  onCategoryChangeList={handleonCategoryChangeList}
             onsizechangelist={onsizechangelist}
            />

            {/* LOOP ITEMS */}
            <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-8 gap-y-10 mt-8 lg:mt-10">
              {DisplayedProducts.map((item, index) => (
                <ProductCard data={item} key={index} dispatch={dispatch}/>
              ))}
            </div>

            {/* PAGINATION */}
            {numToDisplay < products.length && (
            <div className="flex flex-col mt-12 lg:mt-16 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-center sm:items-center">
              {isLoading ? (
            <ButtonPrimary>
              <b>Loading...</b>
            </ButtonPrimary>
          ) : (
            <ButtonPrimary onClick={() => showMore()}>
              Show me more
            </ButtonPrimary>
          )}
            </div>
            )}
          </main>
        </div>

        {/* === SECTION 5 === */}
        {/* <hr className="border-slate-200 dark:border-slate-700" /> */}

        {/* <SectionSliderCollections /> */}
        {/* <hr className="border-slate-200 dark:border-slate-700" /> */}

        {/* SUBCRIBES */}
        {/* <SectionPromo1 /> */}
      </div>
    </div>
  );
};

export default PageCollection;
