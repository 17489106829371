import React, { FC, useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import Pagination from "shared/Pagination/Pagination";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import SectionSliderCollections from "components/SectionSliderLargeProduct";
import SectionPromo1 from "components/SectionPromo1";
import HeaderFilterSearchPage from "components/HeaderFilterSearchPage";
import Input from "shared/Input/Input";
import ButtonCircle from "shared/Button/ButtonCircle";
import ProductCard from "components/ProductCard";
import { Product, PRODUCTS } from "data/data";
import { app_name, api_url } from "common/Helpers";
import { useCart } from "../context/Context";
import { useParams } from "react-router-dom";
import axios from "axios";
export interface PageSearchProps {
  data?: Product[];
  className?: string;
}
const PageSearch: FC<PageSearchProps> = ({ className = "" }) => {
  const [products, setProducts] = useState<Product[]>([]);
  const [filterproduct, setfilterproduct] = useState<Product[]>([]);
  const { dispatch } = useCart();
  const [searchKey, setsearchKey] = useState("");
  const [displayedProducts, setDisplayedProducts] = useState<Product[]>([]);
  const [numToDisplay, setNumToDisplay] = useState(8);
  const [isLoading, setIsLoading] = useState(false);
  const { variable } = useParams();
  const [paramsearch, setparamsearch] = useState<string>('');
  useEffect(() => {
    // Apply filter whenever `variable` changes and products are available
    
  }, [variable, products]);

  const showMore = () => {
    setIsLoading(true);
    setTimeout(() => {
      setNumToDisplay(numToDisplay + 8);

      const apiUrl = api_url + "getproductsbycat";

      axios
        .get(apiUrl)
        .then((response) => {
          setProducts(response.data as Product[]);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }, 100);
  };
  const handleCategoryChange = (categoryId: string) => {
    if (categoryId === "All items") {
      const apiUrl = api_url + "getproductsbycat";
      setIsLoading(true);
      // setTimeout(() => {
      axios
        .get(apiUrl)
        .then((response) => {
          setDisplayedProducts(response.data as Product[]);
          setfilterproduct(response.data as Product[]);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        })
        .finally(() => {
          setIsLoading(false);
        });
      // }, 1000);
    } else {
      const apiUrl = api_url + "getproductsbycat/" + categoryId;
      setIsLoading(true);
      // setTimeout(() => {
      axios
        .get(apiUrl)
        .then((response) => {
          setDisplayedProducts(response.data as Product[]);
          setfilterproduct(response.data as Product[]);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        })
        .finally(() => {
          setIsLoading(false);
        });
      // }, 5000);
    }
  };
  useEffect(() => {
    const apiUrl = api_url + "getproductsbycat";
    axios
      .get(apiUrl)
      .then((response) => {
        setProducts(response.data as Product[]);
        setfilterproduct(response.data as Product[]);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);
  useEffect(() => {
    if (variable && products.length > 0) {
      setsearchKey(variable);
      const searchTerm = variable.toLowerCase();
      const filteredProducts = products.filter(
        (item) => item.name && item.name.toLowerCase().includes(searchTerm)
      );
      console.log("S", filteredProducts);
      setDisplayedProducts(filteredProducts);
    }else{
      setDisplayedProducts(products.slice(0, numToDisplay));
    }
  }, [products, numToDisplay]);

  const handleproductFilter = (value: string) => {
    setsearchKey(value);
    const searchTerm = value.toLowerCase();
    const filteredProducts = products.filter(
      (item) => item.name && item.name.toLowerCase().includes(searchTerm)
    );
    setDisplayedProducts(filteredProducts);
  };
  return (
    <div className={`nc-PageSearch  ${className}`} data-nc-id="PageSearch">
      <Helmet>
        <title>Search || {app_name}</title>
      </Helmet>

      <div
        className={`nc-HeadBackgroundCommon h-24 2xl:h-28 top-0 left-0 right-0 w-full bg-primary-50 dark:bg-neutral-800/20 `}
        data-nc-id="HeadBackgroundCommon"
      />
      <div className="container">
        <header className="max-w-2xl mx-auto -mt-10 flex flex-col lg:-mt-7">
          <form
            className="relative w-full"
            onSubmit={(e) => e.preventDefault()}
          >
            <label
              htmlFor="search-input"
              className="text-neutral-500 dark:text-neutral-300"
            >
              <span className="sr-only">Search all icons</span>
              <Input
                className="shadow-lg border-0 dark:border"
                id="search-input"
                value={searchKey}
                type="search"
                placeholder="Type your keywords"
                sizeClass="pl-14 py-5 pr-5 md:pl-16"
                rounded="rounded-full"
                onChange={(e) => {
                  handleproductFilter(e.target.value);
                  // setparamsearch("");
                }}
              />
              <ButtonCircle
                className="absolute right-2.5 top-1/2 transform -translate-y-1/2"
                size=" w-11 h-11"
                type="submit"
              >
                <i className="las la-arrow-right text-xl"></i>
              </ButtonCircle>
              <span className="absolute left-5 top-1/2 transform -translate-y-1/2 text-2xl md:left-6">
                <svg
                  className="h-5 w-5"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M22 22L20 20"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            </label>
          </form>
        </header>
      </div>

      <div className="container py-16 lg:pb-28 lg:pt-20 space-y-16 lg:space-y-28">
        <main>
          {/* FILTER */}
          <HeaderFilterSearchPage onCategoryChange={handleCategoryChange} />

          {/* LOOP ITEMS */}
          <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-8 gap-y-10 mt-8 lg:mt-10">
            {displayedProducts.map((item) => (
              <ProductCard data={item} key={item.id} dispatch={dispatch} />
            ))}
          </div>

          {/* PAGINATION */}
          {numToDisplay < products.length && (
            <div className="flex mt-16 justify-center items-center">
              {isLoading ? (
                <ButtonPrimary>
                  <b>Loading...</b>
                </ButtonPrimary>
              ) : (
                <ButtonPrimary onClick={() => showMore()}>
                  Show me more
                </ButtonPrimary>
              )}
            </div>
          )}
        </main>

        {/* === SECTION 5 === */}
        <hr className="border-slate-200 dark:border-slate-700" />
        <SectionSliderCollections />
        {/* <hr className="border-slate-200 dark:border-slate-700" /> */}

        {/* SUBCRIBES */}
        {/* <SectionPromo1 /> */}
      </div>
    </div>
  );
};

export default PageSearch;
