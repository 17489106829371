import React from "react";
import { Link } from "react-router-dom";
import { CheckCircleIcon } from "@heroicons/react/24/outline";

const SuccessPage = () => {
  const whatsappLink = "https://api.whatsapp.com/send/?phone=918125846278&text&type=phone_number&app_absent=0";

  return (
    <div className="flex flex-col items-center justify-center h-[60vh]">
      <div className="text-center">
        <CheckCircleIcon className="w-12 h-15 text-green-600 mx-auto mb-4" /> {/* Add the Verify Icon */}
        <h2 className="text-3xl font-semibold text-green-600 mt-2 mb-4">
          Order Placed Successfully!
        </h2>
        <p className="text-neutral-800 dark:text-neutral-200">
          Thank you for your order. Your order has been successfully placed.
        </p>
        <p className="text-neutral-800 dark:text-neutral-200 mt-2">
          For more details, check your email address.
        </p>
        <a href={whatsappLink} target="_blank" rel="noopener noreferrer" className="text-green-600 mt-4 block">
          Contact Us on WhatsApp
        </a>
        <Link to="/" className="text-green-600 mt-4 block">
          Back to Home
        </Link>
      </div>
    </div>
  );
};

export default SuccessPage;
