import React, { FC,useEffect,useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import LikeButton from "components/LikeButton";
import { StarIcon } from "@heroicons/react/24/solid";
import BagIcon from "components/BagIcon";
import NcInputNumber from "components/NcInputNumber";
import { PRODUCTS } from "data/data";
import {
  NoSymbolIcon,
  ClockIcon,
  SparklesIcon,
} from "@heroicons/react/24/outline";
import axios from "axios";
import { api_url} from "../common/Helpers";
import IconDiscount from "components/IconDiscount";
import Prices from "components/Prices";
import toast from "react-hot-toast";
import detail1JPG from "images/products/detail1.jpg";
import detail2JPG from "images/products/detail2.jpg";
import detail3JPG from "images/products/detail3.jpg";
import NotifyAddTocart from "./NotifyAddTocart";
import AccordionInfo from "containers/ProductDetailPage/AccordionInfo";
import { Link } from "react-router-dom";
import { useCart } from "../context/Context";
import { api_assets_url } from "common/Helpers";

export interface ProductQuickViewProps {
  className?: string;
  productdata?: any;
}
interface StockItem {
  id: number;
  productid: string;
  productname: string;
  psize: string;
  pcolor: string;
  pquantity: number;
}
const ProductQuickView: FC<ProductQuickViewProps> = ({
  className = "",
  productdata,
}) => {
  const { variants, status } = PRODUCTS[0];
  const {
    id,
    name,
    price,
    description,
    variantType,
    image,
    size,
    allOfSizes,
    allattribute,
  } = productdata;
  const LIST_IMAGES_DEMO = [detail1JPG, detail2JPG, detail3JPG];
  const [variantActive, setVariantActive] = React.useState(0);
  const [variantname, setvariantname] = React.useState(allattribute?.name[0]);
  const [sizeSelected, setSizeSelected] = React.useState(size ? size[0] : "");
  const [qualitySelected, setQualitySelected] = React.useState(1);
  const [newprice, setnewprice] = React.useState(
    allattribute.price[0] ? Number(allattribute.price[0]) : price
  );
  const [quantity, setQuantity] = useState<number | null>(null);

  const [stockqtydata, setStockqtydata] = useState<StockItem[]>([]);
  useEffect(() => {
    // Ensure that variantname is "pcolor" and sizeSelected is "psize"
    if (variantname && sizeSelected) {
      // Find the item with matching pcolor and psize in stockqtydata
      const matchingItem = stockqtydata.find(
        (item) => item.pcolor === variantname && item.psize === sizeSelected
      );

      // If a matching item is found, set the quantity to its pquantity
      if (matchingItem) {
        setQuantity(Number(matchingItem.pquantity));
      } else {
        // If no matching item is found, set quantity to null or any default value you prefer
        setQuantity(null);
      }
    } else {
      // If variantname or sizeSelected don't match the criteria, set quantity to null
      setQuantity(null);
    }
  }, [variantname, sizeSelected, stockqtydata]);
  const { dispatch } = useCart();
  const notifyAddTocart = ({ size }: { size?: string }) => {
    const action = {
      type: "ADD_TO_CART", // Use the appropriate action type from your context
      payload: {
        id: id,
        name: name,
        price: newprice,
        unitPrice: newprice,
        description: description,
        size: sizeSelected,
        variants: allOfSizes,
        variantType: variantType,
        allattribute: variantname,
        status: status,
        image: image,
        havestock: quantity,
        qty: qualitySelected,
      },
    };
    dispatch(action);
    toast.custom(
      (t) => (
        <NotifyAddTocart
          title={productdata?.name}
          price={newprice ? qualitySelected *  newprice : productdata?.price}
          productImage={productdata?.image}
          qualitySelected={qualitySelected}
          show={t.visible}
          sizeSelected={sizeSelected}
          variantActive={variantActive}
          variantname={variantname}
        />
      ),
      { position: "top-right", id: "nc-product-notify", duration: 3000 }
    );
  };
  useEffect(() => {
    // Make an HTTP GET request to the API
    axios
      .get(api_url + "getproductbyid/" + id)
      .then((response) => {
        setStockqtydata(response.data.sizecolorqty);
        if (

          response.data.pdata.allOfSizes &&
          response.data.pdata.allOfSizes.length > 0
        ) {
          setSizeSelected(response.data.pdata.allOfSizes[0]);
        }
        if (response.data) {
          setnewprice(response.data.pdata.allattribute.price[0])
          setvariantname(response.data.pdata.allattribute.name[0])
        }

      })
      .catch((error) => {
        console.error("Error fetching product data:", error);
      });
  }, []);
  const renderVariants = () => {
    if (!allattribute.name || !allattribute.name.length) {
      return null;
    }
    const handleVariantClick = (index: any, name: any) => {
      setVariantActive(index);
      setvariantname(name);
      setnewprice(Number(allattribute.price[index]));
    };
    return (
      <div>
        <label htmlFor="">
          <span className="text-sm font-medium">
            Attribute:
            <span className="ml-1 font-semibold">{variantname}</span>
          </span>
        </label>
        <div className="flex mt-2.5">
          {allattribute.name.map((name: any, index: any) => (
            <div
              key={index}
              onClick={() => handleVariantClick(index, name)}
              className={`relative flex-1 max-w-[75px] h-10 rounded-full border-2 cursor-pointer ${
                variantActive === index
                  ? "border-transparent"
                  : "border-primary-6000 dark:border-primary-500"
              }`}
            >
              <div
                className={`relative h-10 sm:h-11 rounded-2xl border flex items-center justify-center 
                text-sm sm:text-base uppercase font-semibold select-none overflow-hidden z-0 cursor-pointer 
                ${
                  variantActive === index
                    ? "bg-primary-6000 border-primary-6000 text-white hover:bg-primary-6000"
                    : "border-transparent"
                }`}
              >
                {name}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderSizeList = () => {
    if (!allOfSizes || !size || !size.length) {
      return null;
    }

    return (
      <div>
        <div className="flex justify-between font-medium text-sm">
          <label htmlFor="">
            <span className="">
              Size:
              <span className="ml-1 font-semibold">{sizeSelected}</span>
            </span>
          </label>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="##"
            className="text-primary-6000 hover:text-primary-500"
          >
            See sizing chart
          </a>
        </div>

        <div className="grid grid-cols-5 sm:grid-cols-7 gap-2 mt-3">
          {Array.isArray(allOfSizes) &&
            allOfSizes.map((size, index) => {
              const isActive = size === sizeSelected;
              const sizeOutStock = !size.includes(size);

              const handleSizeSelection = (selectedSize: any) => {
                if (sizeOutStock) {
                  return;
                }
                setSizeSelected(selectedSize);
              };
              return (
                <div
                  key={index}
                  className={`relative h-10 sm:h-11 rounded-2xl border flex items-center justify-center 
                text-sm sm:text-base uppercase font-semibold select-none overflow-hidden z-0 ${
                  sizeOutStock
                    ? "text-opacity-20 dark:text-opacity-20 cursor-not-allowed"
                    : "cursor-pointer"
                } ${
                    isActive
                      ? "bg-primary-6000 border-primary-6000 text-white hover:bg-primary-6000"
                      : "border-slate-300 dark:border-slate-600 text-slate-900 dark:text-slate-200 hover:bg-neutral-50 dark:hover-bg-neutral-700"
                  }`}
                  onClick={() => {
                    if (sizeOutStock) {
                      return;
                    }
                    setSizeSelected(size);
                    setQualitySelected(1);
                  }}
                >
                  {size}
                  
                </div>
              );
            })}
        </div>
      </div>
    );
  };

  const renderStatus = () => {
    if (!status) {
      return null;
    }
    const CLASSES =
      "absolute top-3 left-3 px-2.5 py-1.5 text-xs bg-white dark:bg-slate-900 nc-shadow-lg rounded-full flex items-center justify-center text-slate-700 text-slate-900 dark:text-slate-300";
    if (status === "New in") {
      return (
        <div className={CLASSES}>
          <SparklesIcon className="w-3.5 h-3.5" />
          <span className="ml-1 leading-none">{status}</span>
        </div>
      );
    }
    if (status === "50% Discount") {
      return (
        <div className={CLASSES}>
          <IconDiscount className="w-3.5 h-3.5" />
          <span className="ml-1 leading-none">{status}</span>
        </div>
      );
    }
    if (status === "Sold Out") {
      return (
        <div className={CLASSES}>
          <NoSymbolIcon className="w-3.5 h-3.5" />
          <span className="ml-1 leading-none">{status}</span>
        </div>
      );
    }
    if (status === "limited edition") {
      return (
        <div className={CLASSES}>
          <ClockIcon className="w-3.5 h-3.5" />
          <span className="ml-1 leading-none">{status}</span>
        </div>
      );
    }
    return null;
  };

  const renderSectionContent = () => {
    return (
      <div className="space-y-8">
        {/* ---------- 1 HEADING ----------  */}
        <div>
          <h2 className="text-2xl font-semibold hover:text-primary-6000 transition-colors">
            <Link to={"/product-detail/" + productdata?.id}>
              {productdata?.name}
            </Link>
          </h2>

          <div className="flex items-center mt-5 space-x-4 sm:space-x-5">
            {/* <div className="flex text-xl font-semibold">$112.00</div> */}
            <Prices
              contentClass="py-1 px-2 md:py-1.5 md:px-3 text-lg font-semibold"
              price={newprice ? newprice : productdata?.price}
            />

            <div className="h-6 border-l border-slate-300 dark:border-slate-700"></div>

            {/* <div className="flex items-center">
              <Link
                to="/product-detail"
                className="flex items-center text-sm font-medium"
              >
                <StarIcon className="w-5 h-5 pb-[1px] text-yellow-400" />
                <div className="ml-1.5 flex">
                  <span>4.9</span>
                  <span className="block mx-2">·</span>
                  <span className="text-slate-600 dark:text-slate-400 underline">
                    142 reviews
                  </span>
                </div>
              </Link>0
              <span className="hidden sm:block mx-2.5">·</span>
              <div className="hidden sm:flex items-center text-sm">
                <SparklesIcon className="w-3.5 h-3.5" />
                <span className="ml-1 leading-none">{status}</span>
              </div>
            </div> */}
          </div>
        </div>

        {/* ---------- 3 VARIANTS AND SIZE LIST ----------  */}
        <div className="">{renderVariants()}</div>
        <div className="">{renderSizeList()}</div>

        {/*  ---------- 4  QTY AND ADD TO CART BUTTON */}
        <div className="flex space-x-3.5">
          <div className="flex items-center justify-center bg-slate-100/70 dark:bg-slate-800/70 px-2 py-3 sm:p-3.5 rounded-full">
          {quantity && quantity > 1 ? (
              <NcInputNumber
                defaultValue={qualitySelected}
                onChange={setQualitySelected}
                maxValue={quantity === null ? undefined : quantity}
              />
            ) : (
              <>
                <div className="rounded-full flex items-center justify-center px-2.5 py-1.5 text-xs text-slate-700 dark:text-slate-300 border border-slate-200 dark:border-slate-700">
                  <NoSymbolIcon className="w-3.5 h-3.5" />
                  <span className="ml-1 leading-none">Sold Out</span>
                </div>
              </>
            )}
          </div>
          <ButtonPrimary
            className="flex-1 flex-shrink-0"
            onClick={() => notifyAddTocart({ size: size })}
          >
            <BagIcon className="hidden sm:inline-block w-5 h-5 mb-0.5" />
            <span className="ml-3">Add to cart</span>
          </ButtonPrimary>
        </div>

        {/*  */}
        <hr className=" border-slate-200 dark:border-slate-700"></hr>
        {/*  */}

        {/* ---------- 5 ----------  */}
        <AccordionInfo
          data={[
            {
              name: "Description",
              content:
                "Fashion is a form of self-expression and autonomy at a particular period and place and in a specific context, of clothing, footwear, lifestyle, accessories, makeup, hairstyle, and body posture.",
            },
            {
              name: "Features",
              content: `<ul class="list-disc list-inside leading-7">
            <li>Material: 43% Sorona Yarn + 57% Stretch Polyester</li>
            <li>
             Casual pants waist with elastic elastic inside
            </li>
            <li>
              The pants are a bit tight so you always feel comfortable
            </li>
            <li>
              Excool technology application 4-way stretch
            </li>
          </ul>`,
            },
          ]}
        />
      </div>
    );
  };

  return (
    <div className={`nc-ProductQuickView ${className}`}>
      {/* MAIn */}
      <div className="lg:flex">
        {/* CONTENT */}
        <div className="w-full lg:w-[50%] ">
          {/* HEADING */}
          <div className="relative">
            <div className="aspect-w-16 aspect-h-16">
              <img
                src={productdata?.image}
                className="w-full rounded-xl object-cover"
                alt="product detail 1"
              />
            </div>

            {/* STATUS */}
            {renderStatus()}
            {/* META FAVORITES */}
            <LikeButton className="absolute right-3 top-3 " />
          </div>
          <div className="hidden lg:grid grid-cols-2 gap-3 mt-3 sm:gap-6 sm:mt-6 xl:gap-5 xl:mt-5">
            {productdata?.featuresimages?.map(
              (item: { name: string }, index: number) => (
                <div key={index} className="aspect-w-3 aspect-h-4">
                  <img
                    src={api_assets_url + "uploads/" + item.name}
                    className="w-full rounded-xl object-cover"
                    alt={`product detail ${index + 1}`}
                  />
                </div>
              )
            )}
          </div>
        </div>

        {/* SIDEBAR */}
        <div className="w-full lg:w-[50%] pt-6 lg:pt-0 lg:pl-7 xl:pl-8">
          {renderSectionContent()}
        </div>
      </div>
    </div>
  );
};

export default ProductQuickView;
