import React, { useState, useEffect } from "react";
import CommonLayout from "./CommonLayout";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { api_url } from "common/Helpers";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import Swal from 'sweetalert2';

interface Order {
  orderid: number;
  date: string;
  status: number;
  totalAmount: number;
}

const AccountOrder = () => {
  const [fetchedData, setFetchedData] = useState<Order[]>([]);
  const [loading, setLoading] = useState(true);
  let userID = localStorage.getItem("userID");
  const navigate = useNavigate();
  const fetchdeta = () => {
    fetch(api_url + "getmyorders/" + userID)
      .then((response) => response.json())
      .then((data) => {
        console.log("Data fetched successfully:", data);
        setFetchedData(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  }
  useEffect(() => {
    fetchdeta();
  }, [userID]);

  const handleViewOrder = (orderdata: any) => {
    localStorage.setItem("orderdata", JSON.stringify(orderdata));
    navigate("/account-myvieworder");
  };
  const orderSts = (id: number) => {
    if (id == 1) {
      return 'Processing';
    } else if (id == 2) {
      return 'Intransit';
    } else if (id == 3) {
      return 'Completed';
    } else  if (id == 5) {
      return 'Canceled';
    }else{
      return 'failed';
    }
  }
  const CancelOrder = (id: number) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Want to cancel this order?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Cancel it!",
      customClass: {
        confirmButton: "swal-button-spacing",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        const formData = new FormData();
        formData.append("orderid", id.toString());
        formData.append("userid", userID ?? "");
        axios
          .post(api_url + "order_cancel", formData)
          .then(({ data }) => {
            Swal.fire({
              title: "Success!",
              text: "Your has been Canceled.",
              icon: "success",
              showConfirmButton: false,
              timer: 900
            });
            fetchdeta(); 
          })
          .catch((error) => {
            console.error("Error deleting order:", error);
          });
      }
    });
  }
  
  // const CancelOrder = (id: number) => {
    
  //   const formData = new FormData();
  //   formData.append("orderid", id.toString());
  //   formData.append("userid", userID ?? "");
  //   axios
  //     .post(api_url + `order_cancel`, formData)
  //     .then(({ data }) => {
  //         toast.success("Order canceled");
  //         fetchdeta();
  //     })
  //     .catch(({ response }) => {
  //       if (response.status === 422) {
  //         console.log(response.data.errors);
  //       } else {
  //         console.log(response.data.message);
  //       }
  //     });
  // }
  return (
    <div>
      <CommonLayout>
        <div className="space-y-10 sm:space-y-12">
          <h2 className="text-2xl sm:text-3xl font-semibold">
            Order History
          </h2>
          {fetchedData.length === 0 ? (
            <p>No orders found.</p>
          ) : (
            fetchedData.map((order, index) => (
              <div
                key={index}
                className="border border-slate-200 dark:border-slate-700 rounded-lg overflow-hidden z-0"
              >
                <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center p-4 sm:p-8 bg-slate-50 dark:bg-slate-500/5">
                  <div>
                    <p className="text-lg font-semibold">
                      Order ID: {order.orderid}
                    </p>
                    <p className="text-slate-500 dark:text-slate-400 text-sm mt-1.5 sm:mt-2">
                      <span>Date: {order.date}</span>

                    </p>
                    <span>Status: {orderSts(order.status)}</span>
                    <p className="text-sm font-semibold">
                      Total Amount:{" "}
                      <span className="text-slate-500">
                        INR {order.totalAmount}
                      </span>
                    </p>
                  </div>
                  <div className="mt-3 sm:mt-0">
                    <ButtonSecondary
                      sizeClass="py-2.5 px-4 sm:px-6 mx-1"
                      fontSize="text-sm font-medium"
                      onClick={() => handleViewOrder(order)}
                    >
                      View Order
                    </ButtonSecondary>
                    {order.status == 1 && (
                      <ButtonSecondary
                        sizeClass="py-2.5 px-4 sm:px-6 mx-1"
                        fontSize="text-sm font-medium"
                        onClick={() => CancelOrder(order.orderid)}
                      >
                        Cancel Order
                      </ButtonSecondary>
                    )}
                  </div>

                </div>
              </div>
            ))
          )}
        </div>
      </CommonLayout>
    </div>
  );
};

export default AccountOrder;
