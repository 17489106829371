import React, { FC, useState, useEffect } from "react";
import HeaderFilterSection from "components/HeaderFilterSection";
import ProductCard from "components/ProductCard";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Product, PRODUCTS } from "data/data";
import axios from "axios";
import { useCart } from "../../context/Context";
import { api_url } from "common/Helpers";

export interface SectionGridFeatureItemsProps {
  data?: Product[];
}

const SectionGridFeatureItems: FC<SectionGridFeatureItemsProps> = ({
  data,
}) => {
  const [products, setProducts] = useState<Product[]>([]);
  const { dispatch } = useCart();
  const [alldisplayedProducts, setalldisplayedProducts] = useState<Product[]>(
    []
  );
  const [displayedProducts, setDisplayedProducts] = useState<Product[]>([]);

  const [numToDisplay, setNumToDisplay] = useState(8);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]); // Add this line

  const showMore = () => {
    setIsLoading(true);
    setTimeout(() => {
      setNumToDisplay(numToDisplay + 8);

      const apiUrl = api_url + "getproductsbycat";

      axios
        .get(apiUrl)
        .then((response) => {
          setProducts(response.data as Product[]);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }, 1000);
  };
  useEffect(() => {
    const apiUrl = api_url + "getproductsbycat";
    axios
      .get(apiUrl)
      .then((response) => {
        setProducts(response.data as Product[]);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);
  useEffect(() => {
    setDisplayedProducts(products.slice(0, numToDisplay));
    setalldisplayedProducts(products.slice(0, numToDisplay));
  }, [products, numToDisplay]);
  const [rangePrices, setRangePrices] = useState<number[]>([]);
  const handleOnChangeRangePrices = (pricerange: number[]) => {
    setRangePrices(pricerange);
  };

  const handleOnSizeChangeList = (sizes: string[]) => {
    // console.log("Sizes:", sizes);

    // If no category filter applied, filter by size only using alldisplayedProducts
    if (!selectedCategories.length) {
      const filteredProducts = alldisplayedProducts.filter((item) => {
        const productSizes = item.size || [];
        return sizes.every((selectedSize) =>
          productSizes.includes(selectedSize as never)
        );
      });

      console.log("Filtered Products (Size):", filteredProducts);
      setDisplayedProducts(filteredProducts);
    } else {
      // If category filter applied, filter by both size and category using displayedProducts
      const filteredProducts = displayedProducts.filter((item) => {
        const productSizes = item.size || [];
        const isSizeMatched = sizes.every((selectedSize) =>
          productSizes.includes(selectedSize as never)
        );

        // Replace 'selectedCategories' with the actual state holding the selected category names
        const productCategory = item.category;
        const isCategoryMatched = selectedCategories.includes(productCategory);

        return isSizeMatched && isCategoryMatched;
      });

      console.log("Filtered Products (Size and Category):", filteredProducts);
      setDisplayedProducts(filteredProducts);
    }
  };

  const handleonCategoryChangeList = (catnames: string[]) => {
    console.log("Categories:", catnames);
    console.log("AllDisplayedProducts:", alldisplayedProducts);
    console.log("Range Prices:", rangePrices);
  
    // Check if no category is selected or only "All Categories" is selected
    if (
      catnames.length === 0 ||
      (catnames.length === 1 && catnames[0] === "All Categories")
    ) {
      // If no category selected, don't filter
      setDisplayedProducts(alldisplayedProducts);
      return;
    }
  
    const filteredProducts = alldisplayedProducts.filter((item) => {
      const productPrice = item.price;
      const productCategory = item.category;
      const isPriceInRange =
        productPrice >= rangePrices[0] && productPrice <= rangePrices[1];
      const isCategoryMatched = catnames.includes(productCategory);
      return isPriceInRange && isCategoryMatched;
    });
  
    console.log("Filtered Products:", filteredProducts);
    setDisplayedProducts(filteredProducts);
  };
  
  
  const handleonCategoryChange = (categoryId: string) => {
    if (categoryId === "All items") {
      const apiUrl = api_url + "getproductsbycat";
      setIsLoading(true);
      setTimeout(() => {
        axios
          .get(apiUrl)
          .then((response) => {
            setDisplayedProducts(response.data as Product[]);
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          })
          .finally(() => {
            setIsLoading(false);
          });
      }, 1000);
    } else {
      const apiUrl = api_url + "getproductsbycat/" + categoryId;
      setIsLoading(true);
      // setTimeout(() => {
      axios
        .get(apiUrl)
        .then((response) => {
          setDisplayedProducts(response.data as Product[]);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        })
        .finally(() => {
          setIsLoading(false);
        });
      // }, 5000);
    }
  };
  useEffect(() => {
    const filteredProducts = alldisplayedProducts.filter((item) => {
      const productPrice = item.price;
      return productPrice >= rangePrices[0] && productPrice <= rangePrices[1];
    });

    setDisplayedProducts(filteredProducts);
  }, [rangePrices, alldisplayedProducts]);

  return (
    <div className="nc-SectionGridFeatureItems relative">
      <HeaderFilterSection
        onCategoryChange={handleonCategoryChange}
        rangePrices={rangePrices}
        onChangeRangePrices={handleOnChangeRangePrices}
        onCategoryChangeList={handleonCategoryChangeList} // Make sure to include this prop
        onsizechangelists={handleOnSizeChangeList} // Add this prop if required
      />
      <div className="grid gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
        {displayedProducts.map((item) => (
          <ProductCard data={item} key={item.id} dispatch={dispatch} />
        ))}
      </div>
      {numToDisplay < products.length && (
        <div className="flex mt-16 justify-center items-center">
          {isLoading ? (
            <ButtonPrimary>
              <b>Loading...</b>
            </ButtonPrimary>
          ) : (
            <ButtonPrimary onClick={() => showMore()}>
              Show me more
            </ButtonPrimary>
          )}
        </div>
      )}
    </div>
  );
};

export default SectionGridFeatureItems;
