import { SocialType } from "shared/SocialsShare/SocialsShare";
import React, { FC ,useState,useEffect} from "react";
import facebook from "images/socials/facebook.svg";
import twitter from "images/socials/twitter.svg";
import telegram from "images/socials/telegram.svg";
import youtube from "images/socials/youtube.svg";
import instagram from "images/socials/instagram.svg";
import { api_url, api_assets_url } from "common/Helpers";

export interface SocialsListProps {
  className?: string;
  itemClass?: string;
  socials?: SocialType[];
}
interface SocialItem {
  socialurl: string;
  socialsicon: string;
  name: string;
  status: string;
}
const socialsDemo: SocialType[] = [
  { name: "Facebook", icon: facebook, href: "https://www.facebook.com/profile.php?id=61550110127549" },
  // { name: "Twitter", icon: twitter, href: "" },
  // { name: "Youtube", icon: youtube, href: "#" },
  { name: "Telegram", icon: instagram, href: "https://www.instagram.com/cfashion.in/?hl=en" },
];

const SocialsList: FC<SocialsListProps> = ({
  className = "",
  itemClass = "block w-6 h-6",
  socials = socialsDemo,
}) => {
  const [SocialData, setSocialData] = useState<SocialItem[]>([]);
  const fetchData = () => {
    fetch(api_url + "getsocialmenus")
      .then((response) => response.json())
      .then((data) => {
        setSocialData(data);
      })
      .catch((error) => console.error('Error fetching data:', error));
  }
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <nav
      className={`nc-SocialsList flex space-x-2.5 text-2xl text-neutral-6000 dark:text-neutral-300 ${className}`}
      data-nc-id="SocialsList"
    >
    {SocialData.map((item, index) => (
      <>
        {item.status == "1" && (
          <>
        <a
          key={index}
          className={`${itemClass}`}
          href={item.socialurl}
          target="_blank"
          rel="noopener noreferrer"
          title={item.name}
        >
          <img src={api_assets_url + 'uploads/' + item.socialsicon} alt="" />
        </a>
        </>
        )}
        </>
      ))}
    </nav>
  );
};

export default SocialsList;
