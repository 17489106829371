// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDN2Skr9jqVOckW6I7rjsHVtWxL5DTvkiE",
    authDomain: "akcommerce-e7267.firebaseapp.com",
    projectId: "akcommerce-e7267",
    storageBucket: "akcommerce-e7267.appspot.com",
    messagingSenderId: "552455377943",
    appId: "1:552455377943:web:2239df303d33d608fc7852",
    measurementId: "G-8CK19LPQSZ"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
