import React, { useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { api_url } from "common/Helpers";
export interface LikeButtonProps {
  className?: string;
  liked?: boolean;
  productid?: string;
  userid?: string;
  addwishlist?:void;
  myLiked?:Number;
}

const LikeButton: React.FC<LikeButtonProps> = ({
  className = "",
  liked = Math.random() > 0.6,
}) => {
  const [isLiked, setIsLiked] = useState(liked);
  function addwishlist() {
    setIsLiked(!isLiked);
    // Ensure that productdata is available and contains the product ID
    let myLiked = 'remove';
    if (1) {
      // Create a FormData object
      const formData = new FormData();
      if(isLiked){
        myLiked = 'remove';
      }else{
        myLiked = 'add';
      }
      formData.append('productid', '1'); // Assuming id is a string
      formData.append('userid', '1'); // Assuming userId is a string
      formData.append('isliked', myLiked.toString()); // Convert myLiked to a string
      // Make a POST request to your API
      axios.post(api_url+'addtowishlist', formData)
        .then((response) => {
          // Handle the successful response here
          toast.success(response.data.text); // You can use a toast or a notification library
        })
        .catch((error) => {
          // Handle errors here
          console.error('API Error:', error);
          toast.error('Failed to add to wishlist');
        });
    } else {
      console.error('Product data is not available.'); // Handle the case where productdata is null
    }
  }

  return (
    <button
      className={`w-9 h-9 flex items-center justify-center rounded-full bg-white dark:bg-slate-900 text-neutral-700 dark:text-slate-200 nc-shadow-lg ${className}`}
      onClick={() => addwishlist()}
    >
      <svg className="w-5 h-5" viewBox="0 0 24 24" fill="none">
        <path
          d="M12.62 20.81C12.28 20.93 11.72 20.93 11.38 20.81C8.48 19.82 2 15.69 2 8.68998C2 5.59998 4.49 3.09998 7.56 3.09998C9.38 3.09998 10.99 3.97998 12 5.33998C13.01 3.97998 14.63 3.09998 16.44 3.09998C19.51 3.09998 22 5.59998 22 8.68998C22 15.69 15.52 19.82 12.62 20.81Z"
          stroke={isLiked ? "#ef4444" : "currentColor"}
          fill={isLiked ? "#ef4444" : "none"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </button>
  );
};

export default LikeButton;
