import React, { FC ,useState,useEffect} from "react";
import { Helmet } from "react-helmet-async";
import Pagination from "shared/Pagination/Pagination";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import SectionSliderCollections from "components/SectionSliderLargeProduct";
import SectionPromo1 from "components/SectionPromo1";
import ProductCard from "components/ProductCard";
import TabFilters from "./TabFilters";
import { PRODUCTS } from "data/data";
import axios from 'axios';
import {useParams } from "react-router-dom";
import { useCart } from "../context/Context"; // Import your custom hook and CartState
import {api_url, app_name} from 'common/Helpers';
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
export interface PageCollectionProps {
  className?: string;
  style?: any;
}
const PageCollection: FC<PageCollectionProps> = ({ className = "" }) => {
  const [products, setProducts] = useState([]);
  const { state, dispatch } = useCart(); // Use your custom hook to access context
  const {catid}= useParams();
  useEffect(() => {
    // Define the API URL
    const apiUrl = api_url+'getproductsbycat/'+ catid;

    // Make the API request using Axios
    axios.get(apiUrl)
      .then((response) => {
        // Handle the successful response here
        setProducts(response.data); // Assuming the API returns an array of products
      })
      .catch((error) => {
        // Handle errors here
        console.error('Error fetching data:', error);
      });
  }, [catid]); // Empty dependency array ensures this effect runs once when the component mounts

  return (
    <div
      className={`nc-PageCollection ${className}`}
      data-nc-id="PageCollection"
    >
      {/* <Helmet>
        <title>Collection || {app_name}</title>
      </Helmet> */}

      <div className="text-center container py-16 lg:pb-28 lg:pt-20 space-y-16 sm:space-y-20 lg:space-y-28">
      <h2 className="block text-2xl sm:text-3xl lg:text-4xl font-semibold">Track Your Order </h2>
           <div className="grid">
              <label className="text-center" >
                <div className="mb-3" style={{fontSize: '20px', fontWeight:500}}>AWB Number</div>
          
                  <Input style={{padding:'25px', width:'50%', margin:'auto'}}
                    placeholder="Enter your AWB Number"
                    type="text"
                    className="mt-1"
                  />
                </label>
            </div>
            <div className="" style={{marginTop: '1.7rem'}}>
                  <ButtonPrimary type="submit">Track Order</ButtonPrimary>
                </div>
        <div className="space-y-10 lg:space-y-14">
               
        {/* HEADING */}
          {/* <div className="max-w-screen-sm">
            <h2 className="block text-2xl sm:text-3xl lg:text-4xl font-semibold">
              Man collection
            </h2>
            <span className="block mt-4 text-neutral-500 dark:text-neutral-400 text-sm sm:text-base">
              We not only help you design exceptional products, but also make it
              easy for you to share your designs with more like-minded people.
            </span>
          </div> */}

          {/* <hr className="border-slate-200 dark:border-slate-700" /> */}
          <main>
            {/* TABS FILTER */}
            {/* <TabFilters /> */}

            {/* LOOP ITEMS */}
            {/* <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-8 gap-y-10 mt-8 lg:mt-10">
              {products.map((item, index) => (
                <ProductCard data={item} key={index} dispatch={dispatch}/>
              ))}
            </div> */}

            {/* PAGINATION */}
            {/* <div className="flex flex-col mt-12 lg:mt-16 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-between sm:items-center">
              <Pagination />
              <ButtonPrimary loading>Show me more</ButtonPrimary>
            </div> */}
          </main>
        </div>

        {/* === SECTION 5 === */}
        {/* <hr className="border-slate-200 dark:border-slate-700" /> */}

        {/* <SectionSliderCollections /> */}
        {/* <hr className="border-slate-200 dark:border-slate-700" /> */}

        {/* SUBCRIBES */}
        {/* <SectionPromo1 /> */}
      </div>
    </div>
  );
};

export default PageCollection;
