import React, { FC } from "react";
import { Helmet } from "react-helmet-async";

export interface PrivacyPolicyProps {
  className?: string;
}

const PrivacyPolicy: FC<PrivacyPolicyProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-PrivacyPolicy overflow-hidden relative ${className}`}
      data-nc-id="PrivacyPolicy"
    >
      <Helmet>
        <title>About || AKBeats Clothing</title>
      </Helmet>

      <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
      <h1>About Us</h1>

<p>Welcome to <strong>akbeatsclothing.com</strong>, your one-stop destination for an exceptional shopping experience. At <strong>AKBeats Clothing</strong>, we're not just a store; we're a community of individuals who are passionate about connecting people with <strong>high-quality products</strong> that enhance their lives.</p>

<h2>Our Story</h2>
<p><strong>AKBeats Clothing</strong> began as a dream shared by a group of friends who believed in the power of online commerce to transform lives. Our journey started in a small garage where we sold <strong>handmade crafts</strong> and <strong>vintage treasures</strong>. As the business grew, so did our commitment to delivering the <strong>best products</strong> to our customers. Today, we serve customers across the globe, and our story continues to unfold.</p>

<h2>Our Mission</h2>
<p>Our mission is simple yet profound: to make life more enjoyable and convenient for everyone. We want to empower people to find joy in <strong>everyday products</strong>, discover <strong>unique treasures</strong>, and connect with a like-minded community.</p>

<h2>Quality & Customer Satisfaction</h2>
<p>We're dedicated to delivering <strong>quality you can trust</strong>. Each product on our platform is rigorously curated to ensure it meets our high standards. We believe that when you choose <strong>AKBeats Clothing</strong>, you're choosing <strong>reliability</strong> and <strong>satisfaction</strong>.</p>

<h2>Wide Product Selection</h2>
<p>At <strong>AKBeats Clothing</strong>, we understand that every customer is unique, and so are their needs. That's why we offer an extensive range of products, from <strong>cutting-edge gadgets</strong> to <strong>eco-friendly household items</strong>, from <strong>stylish fashion</strong> to <strong>personal care essentials</strong>. Our ever-expanding catalog is designed to cater to your <strong>diverse interests</strong> and <strong>requirements</strong>.</p>

<h2>Customer-Centric Approach</h2>
<p>Our customers are our most valuable asset. Your happiness is our priority, and we're committed to delivering top-notch service. Our <strong>friendly</strong> and <strong>knowledgeable</strong> customer service team is available to assist you with any questions, concerns, or feedback. We're here to make your shopping experience as <strong>seamless</strong> and <strong>enjoyable</strong> as possible.</p>

<h2>Secure Shopping Experience</h2>
<p>Your online security is of utmost importance to us. We've implemented advanced security measures to safeguard your personal and financial information. Shop with <strong>confidence</strong>, knowing that your data is <strong>protected</strong>.</p>

<p>Thank you for choosing <strong>AKBeats Clothing</strong> as your trusted online shopping partner. We're excited to serve you and provide you with quality products and outstanding service. Our commitment to your satisfaction is unwavering, and we're always eager to hear from you.</p>

<p>Join our community, explore our ever-evolving catalog, and find the joy in shopping at <strong>akbeatsclothing.com</strong>.</p>
        </div>
    </div>
  );
};

export default PrivacyPolicy;
