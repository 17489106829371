import React, { FC } from "react";
import { Helmet } from "react-helmet-async";

interface CancellationProps {
  className?: string;
}

const CancellationPolicy: FC<CancellationProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-PageCancellation overflow-hidden relative ${className}`}
      data-nc-id="PageCancellation"
    >
      <Helmet>
        <title>Cancellation Policy || AKBeats Clothing</title>
      </Helmet>

      <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
        <h1 className="text-2xl lg:text-4xl font-bold mb-4">
          Cancellation Policy
        </h1>
        <p className="text-lg lg:text-xl leading-loose">
          At AKBeats Clothing, we strive to provide the best experience for our customers. We understand that there may be situations where you need to cancel your order. This Cancellation Policy outlines the terms and conditions for cancelling an order on our platform.
        </p>

        {/* Add your specific sections for the Cancellation Policy here */}

        {/* Example Section */}
        <h1 className="text-xl lg:text-2xl font-bold mt-1">
          1. Order Cancellation Eligibility
        </h1>
        <p className="text-base lg:text-lg leading-loose">
          You can cancel your order under the following conditions:
        </p>
        <ul className="list-disc pl-8">
          <li>The order has not been shipped or processed.</li>
          <li>You submit the cancellation request within 24 hours of placing the order.</li>
          {/* Add more specific conditions based on your policy */}
        </ul>

        {/* Add more sections based on your specific Cancellation Policy */}

        {/* Example Section */}
        <h1 className="text-xl lg:text-2xl font-bold mt-1">
          2. Cancellation Process
        </h1>
        <p className="text-base lg:text-lg leading-loose">
          To cancel your order, please follow these steps:
        </p>
        <ol className="list-decimal pl-8">
          <li>Log in to your account and go to the "Orders" section.</li>
          <li>Find the order you wish to cancel and select the cancellation option.</li>
          {/* Add more specific steps based on your policy */}
        </ol>

        {/* Add more sections as necessary based on your specific Cancellation Policy */}
      </div>
    </div>
  );
};

export default CancellationPolicy;
