import React, { FC } from 'react';
import { Helmet } from 'react-helmet-async';

export interface ShippingPolicyProps {
  className?: string;
}

const ShippingPolicy: FC<ShippingPolicyProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-ShippingPolicy overflow-hidden relative ${className}`}
      data-nc-id="ShippingPolicy"
    >
      <Helmet>
        <title>Shipping Policy || AK Beats Clothing</title>
      </Helmet>

      <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
        <h1 className="text-2xl lg:text-4xl font-bold mb-4">
          Shipping Policy
        </h1>

        <section>
          <h2 className="text-xl lg:text-2xl font-bold mt-1">1. Introduction</h2>
          <p className="text-base lg:text-lg leading-loose">
            Welcome to AK Beats Clothing! Our Shipping Policy is designed to ensure a smooth and satisfying shopping experience for all our customers. By placing an order with us, you agree to the terms set out in this policy.
          </p>
        </section>

        <section>
          <h2 className="text-xl lg:text-2xl font-bold mt-1">2. Shipping Rates & Delivery Estimates</h2>
          <p className="text-base lg:text-lg leading-loose">
            Shipping charges for your order will be calculated and displayed at checkout. Delivery estimates depend on your location and selected shipping method.
          </p>
        </section>

        <section>
          <h2 className="text-xl lg:text-2xl font-bold mt-1">3. Shipment Processing Time</h2>
          <p className="text-base lg:text-lg leading-loose">
            All orders are processed within 2-3 business days. Orders are not shipped or delivered on weekends or holidays.
          </p>
        </section>

        <section>
          <h2 className="text-xl lg:text-2xl font-bold mt-1">4. Shipment Confirmation & Order Tracking</h2>
          <p className="text-base lg:text-lg leading-loose">
            You will receive a Shipment Confirmation email once your order has shipped containing your tracking number(s).
          </p>
        </section>

        <section>
          <h2 className="text-xl lg:text-2xl font-bold mt-1">5. International Shipping</h2>
          <p className="text-base lg:text-lg leading-loose">
            We offer international shipping to various countries. Shipping rates and delivery times vary by destination.
          </p>
        </section>

        <section>
          <h2 className="text-xl lg:text-2xl font-bold mt-1">6. Customs, Duties, and Taxes</h2>
          <p className="text-base lg:text-lg leading-loose">
            AK Beats Clothing is not responsible for any customs and taxes applied to your order. All fees imposed during or after shipping are the responsibility of the customer.
          </p>
        </section>

        <section>
          <h2 className="text-xl lg:text-2xl font-bold mt-1">7. Damages</h2>
          <p className="text-base lg:text-lg leading-loose">
            AK Beats Clothing is not liable for any products damaged or lost during shipping. If you received your order damaged, please contact the shipment carrier to file a claim.
          </p>
        </section>

        <section>
          <h2 className="text-xl lg:text-2xl font-bold mt-1">8. Returns Policy</h2>
          <p className="text-base lg:text-lg leading-loose">
            Our Return & Refund Policy provides detailed information about options and procedures for returning your order.
          </p>
        </section>

        <section>
          <h2 className="text-xl lg:text-2xl font-bold mt-1">9. Contact Information</h2>
          <p className="text-base lg:text-lg leading-loose">
            For any inquiries regarding our Shipping Policy, please contact us at: [insert contact email/phone number].
          </p>
        </section>
      </div>
    </div>
  );
};

export default ShippingPolicy;
