import Label from "components/Label/Label";
import NcInputNumber from "components/NcInputNumber";
import Prices from "components/Prices";
import { Product, PRODUCTS } from "data/data";
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useNavigate } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";

import PaymentMethod from "./PaymentMethod";
import ShippingAddress from "./ShippingAddress";
import { useCart } from "../../context/Context"; // Adjust the relative path as needed
import { actionTypes } from "../../context/Reducers"; // Import your action types
// Remove this line, as there is no useRazorpay hook in react-razorpay
import { app_name, api_url, app_url } from "common/Helpers";
import { useCallback, useEffect } from "react";
import useRazorpay, { RazorpayOptions } from "react-razorpay";
import axios from "axios";
import toast from "react-hot-toast";
const CheckoutPage = () => {
  let userName = localStorage.getItem("userName");
  let userID = localStorage.getItem("userID");
  let userEmail = localStorage.getItem("userEmail");
  // Step 1: Create state variables to hold input data
  const [contactInfo, setContactInfo] = useState({
    fullName: userName || "",
    email: userEmail || "",
    phone: "",
    // Add more fields as needed
  });
  const [shippingAddress, setShippingAddress] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    address: "",
    aptSuite: "",
    state: "",
    city: "",
    country: "",
    stateProvince: "",
    postalCode: "",
  });
  let userNumber = shippingAddress.phone.toString() || "9382402973";
  const [paymentMethod, setPaymentMethod] = useState({
    iscod: "",
  });
  const handleContactInfoChange = (newContactInfo: any) => {
    // Step 2: Update the contactInfo state
    setContactInfo(newContactInfo);
  };

  const handleShippingAddressChange = (newShippingAddress: any) => {
    // Step 2: Update the shippingAddress state
    setShippingAddress(newShippingAddress);
  };

  const handlePaymentMethodChange = (newPaymentMethod: any) => {
    // Step 2: Update the paymentMethod state
    setPaymentMethod(newPaymentMethod);
  };
  const validateForm = () => {
    const requiredFields: { [key: string]: string } = {
      // fullName: "Full Name",

      firstName: "First Name",
      lastName: "Last Name",
      email: "Email",
      phone: "Phone",
      address: "Address",
      aptSuite: "Apt/Suite",
      city: "City",
      state: "State",
      postalCode: "Postal Code",
    };

    const emptyFields: string[] = [];

    for (const field in requiredFields) {
      const input = document.getElementById(field) as HTMLInputElement;
      if (!input.value.trim()) {
        emptyFields.push(requiredFields[field]);
      }
    }

    if (emptyFields.length > 0) {
      const emptyFieldNames = emptyFields.join(", ");
      // Handle validation error
      toast.error(
        `Please fill in the following required fields: ${emptyFieldNames}`,
        {
          position: "top-right",
        }
      );
      return false;
    } else if (calculateTotalPrice() === 0) {
      alert("Total price is 0. Please select items to purchase.");
      return false;
    }

    return true; // All required fields have values
  };

  const [Razorpay, isLoaded] = useRazorpay();
  // const handlePayment = useCallback(() => {
  //   if (validateForm()) {
  //   // const order = await createOrder(params);
  //   const options: RazorpayOptions = {
  //     key: "rzp_live_podJpNGg4hxv3P",
  //     amount: (calculateTotalPrice()*100).toString(),
  //     currency: "INR",
  //     name: app_name,
  //     description: "Test Transaction",
  //     image: "http://onecommerce.in/favicon.png",
  //     order_id: '',
  //     handler: (res: any) => {
  //       console.log(res);
  //       if(res.razorpay_payment_id){
  //         updatepaymenttopaid(res.razorpay_payment_id)
  //       }
  //     },
  //     prefill: {
  //       name: userName || "", // Use localStorage value if available, otherwise use an empty string
  //       email:  userEmail|| "", // Use localStorage value if available, otherwise use an empty string
  //       contact: userNumber || "", // Use localStorage value if available, otherwise use an empty string
  //     },
  //     notes: {
  //       address: "Razorpay Corporate Office",
  //     },
  //     theme: {
  //       color: "#3399cc",
  //     },
  //   };

  //   const rzpay = new Razorpay(options);
  //   rzpay.open();
  // }
  // }, [Razorpay]);

  const navigate = useNavigate();
  const updatepaymenttopaid = (paymentid: string) => {
    // const fullNameInput = document.getElementById("fullName") as HTMLInputElement;
    // Access the HTMLInputElement values directly
    const firstNameInput = document.getElementById(
      "firstName"
    ) as HTMLInputElement;
    const lastNameInput = document.getElementById(
      "lastName"
    ) as HTMLInputElement;
    const emailInput = document.getElementById("email") as HTMLInputElement;
    const phoneInput = document.getElementById("phone") as HTMLInputElement;
    const addressInput = document.getElementById("address") as HTMLInputElement;
    const aptSuiteInput = document.getElementById(
      "aptSuite"
    ) as HTMLInputElement;
    const cityInput = document.getElementById("city") as HTMLInputElement;
    const stateInput = document.getElementById("state") as HTMLInputElement;
    const postalCodeInput = document.getElementById(
      "postalCode"
    ) as HTMLInputElement;
    const formData = new FormData();
    // Append the values directly from HTMLInputElement
    formData.append("totalprice", calculateTotalPrice());
    formData.append("paymentid", paymentid);
    formData.append("userID", userID || '');
    // formData.append('fullname', fullNameInput.value);
    formData.append("cartdata", JSON.stringify(cart));
    formData.append("firstname", firstNameInput.value);
    formData.append("lastname", lastNameInput.value);
    formData.append("email", emailInput.value);
    formData.append("contact", phoneInput.value);
    formData.append("address", addressInput.value);
    formData.append("aptsuite", aptSuiteInput.value);
    formData.append("city", cityInput.value);
    formData.append("state", stateInput.value);
    formData.append("postalcode", postalCodeInput.value);
    axios
      .post(api_url + `checkout`, formData)
      .then(({ data }) => {
        console.log(data);
        // After successful payment, clear the cart
        dispatch({ type: actionTypes.CLEAR_CART });
        navigate(app_url + "successpage");
      })
      .catch(({ response }) => {
        if (response.status === 422) {
          console.log(response.data.errors);
        } else {
          console.log(response.data.message);
        }
      }); 
  };
  const handlePaymentcash = async () => {
    if (!validateForm()) {
      // Handle form validation failure
      return;
    }
    const firstNameInput = document.getElementById(
      "firstName"
    ) as HTMLInputElement;
    const lastNameInput = document.getElementById(
      "lastName"
    ) as HTMLInputElement;
    const emailInput = document.getElementById("email") as HTMLInputElement;
    const phoneInput = document.getElementById("phone") as HTMLInputElement;
    const addressInput = document.getElementById("address") as HTMLInputElement;
    const aptSuiteInput = document.getElementById(
      "aptSuite"
    ) as HTMLInputElement;
    const cityInput = document.getElementById("city") as HTMLInputElement;
    const stateInput = document.getElementById("state") as HTMLInputElement;
    const postalCodeInput = document.getElementById(
      "postalCode"
    ) as HTMLInputElement;
    const formData = new FormData();
    // Append the values directly from HTMLInputElement
    formData.append("totalprice", calculateTotalPrice());
    formData.append("paymentid", "");
    formData.append("userID", userID || '');
    // formData.append('fullname', fullNameInput.value);
    formData.append("cartdata", JSON.stringify(cart));
    formData.append("firstname", firstNameInput.value);
    formData.append("lastname", lastNameInput.value);
    formData.append("email", emailInput.value);
    formData.append("contact", phoneInput.value);
    formData.append("address", addressInput.value);
    formData.append("aptsuite", aptSuiteInput.value);
    formData.append("city", cityInput.value);
    formData.append("state", stateInput.value);
    formData.append("postalcode", postalCodeInput.value);
    axios
      .post(api_url + `checkout`, formData)
      .then(({ data }) => {
        console.log(data);
        // After successful payment, clear the cart
        dispatch({ type: actionTypes.CLEAR_CART });
        navigate(app_url+'successpage');
      })
      .catch(({ response }) => {
        if (response.status === 422) {
          console.log(response.data.errors);
        } else {
          console.log(response.data.message);
        }
      });
  };
  const [tabActive, setTabActive] = useState<
    "ContactInfo" | "ShippingAddress" | "PaymentMethod"
  >("ShippingAddress");

  const handleScrollToEl = (id: string) => {
    const element = document.getElementById(id);
    setTimeout(() => {
      element?.scrollIntoView({ behavior: "smooth" });
    }, 80);
  };
  const {
    state: { cart },
    dispatch,
  } = useCart();
  // Function to handle item removal
  const removeFromCart = (itemId: number,ColorName: string,sizeId: string) => {
    dispatch({ type: actionTypes.REMOVE_FROM_CART, payload: { id: itemId, allattribute: ColorName, size: sizeId  } });
  };
  // Calculate the total price of items in the cart
  const calculateTotalPrice = () => {
    return cart
      .reduce((total: number, item: Product) => total + Number(item.price), 0);
  };
  const renderProduct = (item: Product, index: number) => {
    const { id, image, price, name, havestock, size,allattribute} = item;
    // Function to handle quantity increment
    const handleQtyIncrement = () => {
      // Dispatch your "QTY_INCREMENT" action here
      dispatch({ type: "QTY_INCREMENT", payload: { id, size, allattribute } });
      calculateTotalPrice();
    };

    // Function to handle quantity decrement
    const handleQtyDecrement = () => {
      // Dispatch your "QTY_DECREMENT" action here
      dispatch({ type: "QTY_DECREMENT", payload: { id, size, allattribute } });
      calculateTotalPrice();
    };
    return (
      <div key={index} className="relative flex py-7 first:pt-0 last:pb-0">
        <div className="relative h-36 w-24 sm:w-32 flex-shrink-0 overflow-hidden rounded-xl bg-slate-100">
          <img
            src={image}
            alt={name}
            className="h-full w-full object-cover rounded-xl"
          />
          <Link
            to={"/product-detail/" + id}
            className="absolute inset-0"
          ></Link>
        </div>
        <div className="ml-3 sm:ml-6 flex flex-1 flex-col">
          <div>
            <div className="flex justify-between ">
              <div className="flex-[1.5] ">
                <h3 className="text-base font-semibold">
                  <Link to={app_url + "product-detail/" + id}>{name}</Link>
                </h3>
                <div className="mt-1.5 sm:mt-2.5 flex text-sm text-slate-600 dark:text-slate-300">
                  <div className="flex items-center space-x-1.5">
                    <svg className="w-4 h-4" viewBox="0 0 24 24" fill="none">
                      <path
                        d="M7.01 18.0001L3 13.9901C1.66 12.6501 1.66 11.32 3 9.98004L9.68 3.30005L17.03 10.6501C17.4 11.0201 17.4 11.6201 17.03 11.9901L11.01 18.0101C9.69 19.3301 8.35 19.3301 7.01 18.0001Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M8.35 1.94995L9.69 3.28992"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M2.07 11.92L17.19 11.26"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M3 22H16"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M18.85 15C18.85 15 17 17.01 17 18.24C17 19.26 17.83 20.09 18.85 20.09C19.87 20.09 20.7 19.26 20.7 18.24C20.7 17.01 18.85 15 18.85 15Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>

                    <span>{allattribute}</span>
                  </div>
                  <span className="mx-4 border-l border-slate-200 dark:border-slate-700 "></span>
                  <div className="flex items-center space-x-1.5">
                    <svg className="w-4 h-4" viewBox="0 0 24 24" fill="none">
                      <path
                        d="M21 9V3H15"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M3 15V21H9"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M21 3L13.5 10.5"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M10.5 13.5L3 21"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>

                    <span>{size}</span>
                  </div>
                </div>

                <div className="mt-3 flex justify-between w-full sm:hidden relative">
                  <select
                    name="qty"
                    id="qty"
                    className="form-select text-sm rounded-md py-1 border-slate-200 dark:border-slate-700 relative z-10 dark:bg-slate-800 "
                  >
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                  </select>
                  <Prices
                    contentClass="py-1 px-2 md:py-1.5 md:px-2.5 text-sm font-medium h-full"
                    price={price}
                  />
                </div>
              </div>

              <div className="hidden flex-1 sm:flex justify-end">
                <Prices price={price} className="mt-0.5" />
              </div>
            </div>
          </div>

          <div className="flex mt-auto pt-4 items-end justify-between text-sm">
            <div className="hidden sm:block text-center relative">
              <NcInputNumber
                className="relative z-10"
                defaultValue={item.qty} // Pass the current quantity as defaultValue
                min={1}
                max={item.havestock} // Set the minimum value for quantity
                onChange={(newQty) => {
                  // You can dispatch your actions here based on value changes
                  if (newQty > item.qty) {
                    handleQtyIncrement();
                  } else if (newQty < item.qty) {
                    handleQtyDecrement();
                  }
                }}
              />
            </div>

            <a
              onClick={() => removeFromCart(id ?? '', allattribute ?? '', size ?? '')}
              href="##"
              className="relative z-10 flex items-center mt-3 font-medium text-primary-6000 hover:text-primary-500 text-sm "
            >
              <span>Remove</span>
            </a>
          </div>
        </div>
      </div>
    );
  };

  const renderLeft = () => {
    return (
      <div className="space-y-8">
        {/* <input type="hidden" id="fullName" name="fullName" value={contactInfo.fullName} /> */}
        <input
          type="hidden"
          id="firstName"
          name="firstName"
          value={shippingAddress.firstName}
        />
        <input
          type="hidden"
          id="lastName"
          name="lastName"
          value={shippingAddress.lastName}
        />
        <input
          type="hidden"
          id="phone"
          name="phone"
          value={shippingAddress.phone}
        />
        <input
          type="hidden"
          id="email"
          name="email"
          value={shippingAddress.email}
        />
        <input
          type="hidden"
          id="address"
          name="address"
          value={shippingAddress.address}
        />
        <input
          type="hidden"
          id="aptSuite"
          name="aptSuite"
          value={shippingAddress.aptSuite}
        />
        <input
          type="hidden"
          id="city"
          name="city"
          value={shippingAddress.city}
        />
        <input
          type="hidden"
          id="state"
          name="state"
          value={shippingAddress.state}
        />
        <input
          type="hidden"
          id="postalCode"
          name="postalCode"
          value={shippingAddress.postalCode}
        />
        <div id="ShippingAddress" className="scroll-mt-24">
          <ShippingAddress
            isActive={tabActive === "ShippingAddress"}
            onOpenActive={() => {
              setTabActive("ShippingAddress");
              handleScrollToEl("ShippingAddress");
            }}
            onCloseActive={() => {
              setTabActive("PaymentMethod");
              handleScrollToEl("PaymentMethod");
            }}
            // Step 3: Pass callback function to update contactInfo
            onChange={handleShippingAddressChange}
          />
        </div>

        <PaymentMethod
          isActive={tabActive === "PaymentMethod"}
          onOpenActive={() => {
            setTabActive("PaymentMethod");
            handleScrollToEl("PaymentMethod");
          }}
          onCloseActive={() => setTabActive("PaymentMethod")}
          onChange={handlePaymentMethodChange}
        />
      </div>
    );
  };
  const handleOnlinePayment = async () => {
    if (!validateForm()) {
      // Handle form validation failure
      return;
    }
    try {
      // const fullNameInput = document.getElementById("fullName") as HTMLInputElement;
      // Access the HTMLInputElement values directly
      const firstNameInput = document.getElementById(
        "firstName"
      ) as HTMLInputElement;
      const lastNameInput = document.getElementById(
        "lastName"
      ) as HTMLInputElement;
      const emailInput = document.getElementById("email") as HTMLInputElement;
      const phoneInput = document.getElementById("phone") as HTMLInputElement;
      const addressInput = document.getElementById(
        "address"
      ) as HTMLInputElement;
      const aptSuiteInput = document.getElementById(
        "aptSuite"
      ) as HTMLInputElement;
      const cityInput = document.getElementById("city") as HTMLInputElement;
      const stateInput = document.getElementById("state") as HTMLInputElement;
      const postalCodeInput = document.getElementById(
        "postalCode"
      ) as HTMLInputElement;
      const formData = new FormData();
      // Append the values directly from HTMLInputElement
      formData.append("totalprice", calculateTotalPrice());
      // formData.append('fullname', fullNameInput.value);
      formData.append("cartdata", JSON.stringify(cart));
      formData.append("firstname", firstNameInput.value);
      formData.append("lastname", lastNameInput.value);
      formData.append("email", emailInput.value);
      formData.append("contact", phoneInput.value);
      formData.append("address", addressInput.value);
      formData.append("aptsuite", aptSuiteInput.value);
      formData.append("city", cityInput.value);
      formData.append("state", stateInput.value);
      formData.append("postalcode", postalCodeInput.value);
      // Append the values directly from HTMLInputElement
      formData.append("totalprice", calculateTotalPrice());
      axios
        .post(api_url + `phonepe`, formData)
        .then(({ data }) => {
          console.log(data)
          window.location.href = data;
          // After successful payment, clear the cart
          // dispatch({ type: actionTypes.CLEAR_CART });
        })
        .catch(({ response }) => {
          if (response.status === 422) {
            console.log(response.data.errors);
          } else {
            console.log(response.data.message);
          }
        });
    } catch (error) {
      // Handle errors if the API call fails
      console.error("API call failed:", error);
    }

    // Additional actions after the API call can be placed here
  };

  return (
    <div className="nc-CheckoutPage">
      <Helmet>
        <title>Checkout || {app_name}</title>
      </Helmet>

      <main className="container py-16 lg:pb-28 lg:pt-20 ">
        <div className="mb-16">
          <h2 className="block text-2xl sm:text-3xl lg:text-4xl font-semibold ">
            Checkout
          </h2>
          <div className="block mt-3 sm:mt-5 text-xs sm:text-sm font-medium text-slate-700 dark:text-slate-400">
            <Link to={"/#"} className="">
              Homepage
            </Link>
            <span className="text-xs mx-1 sm:mx-1.5">/</span>
            <Link to={"/#"} className="">
              Clothing Categories
            </Link>
            <span className="text-xs mx-1 sm:mx-1.5">/</span>
            <span className="underline">Checkout</span>
          </div>
        </div>

        <div className="flex flex-col lg:flex-row">
          <div className="flex-1">{renderLeft()}</div>

          <div className="flex-shrink-0 border-t lg:border-t-0 lg:border-l border-slate-200 dark:border-slate-700 my-10 lg:my-0 lg:mx-10 xl:lg:mx-14 2xl:mx-16 "></div>

          <div className="w-full lg:w-[36%] ">
            <h3 className="text-lg font-semibold">Order summary</h3>
            <div className="mt-8 divide-y divide-slate-200/70 dark:divide-slate-700 ">
              {/* {[PRODUCTS[0], PRODUCTS[2], PRODUCTS[3]].map(renderProduct)} */}
              {cart.map(renderProduct)}
            </div>

            <div className="mt-10 pt-6 text-sm text-slate-500 dark:text-slate-400 border-t border-slate-200/70 dark:border-slate-700 ">
              <div>
                <Label className="text-sm">Discount code</Label>
                <div className="flex mt-1.5">
                  <Input sizeClass="h-10 px-4 py-3" className="flex-1" />
                  <button className="text-neutral-700 dark:text-neutral-200 border border-neutral-200 dark:border-neutral-700 hover:bg-neutral-100 rounded-2xl px-4 ml-3 font-medium text-sm bg-neutral-200/70 dark:bg-neutral-700 dark:hover:bg-neutral-800 w-24 flex justify-center items-center transition-colors">
                    Apply
                  </button>
                </div>
              </div>

              <div className="mt-4 flex justify-between py-2.5">
                <span>Subtotal</span>
                <span className="font-semibold text-slate-900 dark:text-slate-200">
                  ₹{calculateTotalPrice()}
                </span>
              </div>
              <div className="flex justify-between py-2.5">
                <span>Shipping estimate</span>
                <span className="font-semibold text-slate-900 dark:text-slate-200">
                  ₹0.00
                </span>
              </div>
              <div className="flex justify-between py-2.5">
                <span>Tax estimate</span>
                <span className="font-semibold text-slate-900 dark:text-slate-200">
                  ₹0.00
                </span>
              </div>
              <div className="flex justify-between font-semibold text-slate-900 dark:text-slate-200 text-base pt-4">
                <span>Order total</span>
                <span>₹{calculateTotalPrice()}</span>
              </div>
            </div>
            {paymentMethod.iscod == "2" ? (
              <ButtonPrimary
                onClick={handleOnlinePayment}
                className="mt-8 w-full"
              >
                Confirm order
              </ButtonPrimary>
            ) : (
              <ButtonPrimary
                onClick={handlePaymentcash}
                className="mt-8 w-full"
              >
                Confirm order
              </ButtonPrimary>
            )}

            <div className="mt-5 text-sm text-slate-500 dark:text-slate-400 flex items-center justify-center">
              <p className="block relative pl-5">
                <svg
                  className="w-4 h-4 absolute -left-1 top-0.5"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M12 8V13"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M11.9945 16H12.0035"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                Learn more{` `}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="##"
                  className="text-slate-900 dark:text-slate-200 underline font-medium"
                >
                  Taxes
                </a>
                <span>
                  {` `}and{` `}
                </span>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="##"
                  className="text-slate-900 dark:text-slate-200 underline font-medium"
                >
                  Shipping
                </a>
                {` `} infomation
              </p>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default CheckoutPage;
