import React, { FC } from "react";
import SectionFounder from "./SectionFounder";
import SectionStatistic from "./SectionStatistic";
import { Helmet } from "react-helmet-async";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionHero from "./SectionHero";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import SectionPromo3 from "components/SectionPromo3";

export interface PageAboutProps {
  className?: string;
}

const RefundPolicy: FC<PageAboutProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-PageAbout overflow-hidden relative ${className}`}
      data-nc-id="PageAbout"
    >
      <Helmet>
        <title>Refund Policy || AKBeats Clothing</title>
      </Helmet>

      <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
        <h1 className="text-2xl lg:text-4xl font-bold mb-4">
          Refund Policy
        </h1>
        <p className="text-lg lg:text-xl leading-loose">
          At AKBeats Clothing, we are committed to delivering the best shopping experience to our customers. We understand that there may be instances where you need to return or exchange a product. This Refund Policy outlines our dedication to customer satisfaction and the terms and conditions for processing refunds and returns.
        </p>

        <h1 className="text-xl lg:text-2xl font-bold mt-1">
          1. Eligibility for Refunds and Returns
        </h1>
        <p className="text-base lg:text-lg leading-loose">
          You are eligible for a refund or return under the following conditions:
        </p>
        <ul className="list-disc pl-8">
          <li>The product is damaged, defective, or not as described.</li>
          <li>You notify us within 7 days of receiving the product.</li>
          <li>The product is in its original packaging, unused, and in the same condition as when it was delivered.</li>
        </ul>

        <h1 className="text-xl lg:text-2xl font-bold mt-1">
          2. Non-Eligible Items
        </h1>
        <p className="text-base lg:text-lg leading-loose">
          The following items are not eligible for refunds or returns:
        </p>
        <ul className="list-disc pl-8">
          <li>Perishable goods.</li>
          <li>Personalized or custom-made items.</li>
          <li>Downloadable digital products.</li>
          <li>Gift cards.</li>
        </ul>

        <h1 className="text-xl lg:text-2xl font-bold mt-1">
          3. Refund Process
        </h1>
        <p className="text-base lg:text-lg leading-loose">
          To request a refund, please follow these steps:
        </p>
        <ol className="list-decimal pl-8">
          <li>Contact our customer support team at support@akbeatsclothing.com within 7 days of receiving the product.</li>
          <li>Provide your order number, a description of the issue, and any relevant photos or documentation.</li>
          <li>Our customer support team will assess your request and guide you through the return process if necessary.</li>
        </ol>

        <h1 className="text-xl lg:text-2xl font-bold mt-1">
          4. Returns
        </h1>
        <p className="text-base lg:text-lg leading-loose">
        If a return is required, we will provide instructions on how to return the product to us. You will be responsible for the return shipping costs, unless the return is due to our error (e.g., you received the wrong product).

        5. Refund Timeframe

        Once we receive your returned item, we will inspect it and notify you of the approval or rejection of your refund. If approved, your refund will be processed, and a credit will be automatically applied to your original method of payment within [X] business days.

        6. Shipping Costs

        Shipping costs are non-refundable unless the return is due to our error. In such cases, we will provide a prepaid shipping label.

        7. Exchanges

        If you need to exchange a product for the same item due to damage or defects, please contact our customer support team, and we will assist you in the exchange process.

        8. Contact Information

        If you have any questions or concerns about our Refund Policy, please contact us at:
        support@akbeatsclothing.com

        9. Changes to this Policy

        We reserve the right to update or change this Refund Policy at any time. Any changes will be posted on this page with the updated date. It is your responsibility to review this policy periodically to stay informed about any changes.

        By shopping on AKBeats Clothing, you agree to the terms and conditions of this Refund Policy.
        </p>
      </div>
    </div>
  );
};

export default RefundPolicy;
