import React, { FC ,useState,useEffect} from "react";
import SectionHowItWork from "components/SectionHowItWork/SectionHowItWork";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionPromo1 from "components/SectionPromo1";
import SectionHero2 from "components/SectionHero/SectionHero2";
import SectionSliderLargeProduct from "components/SectionSliderLargeProduct";
import SectionSliderProductCard from "components/SectionSliderProductCard";
import DiscoverMoreSlider from "components/DiscoverMoreSlider";
import SectionGridMoreExplore from "components/SectionGridMoreExplore/SectionGridMoreExplore";
import SectionPromo2 from "components/SectionPromo2";
import SectionSliderCategories from "components/SectionSliderCategories/SectionSliderCategories";
import SectionGridFeatureItems from "./SectionGridFeatureItems";
import SectionPromo3 from "components/SectionPromo3";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import SectionMagazine5 from "containers/BlogPage/SectionMagazine5";
import Heading from "components/Heading/Heading";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { PRODUCTS, SPORT_PRODUCTS } from "data/data";
import axios from 'axios';
import { api_assets_url, api_url } from "common/Helpers";
function PageHome() {

  const UNIQUE_CLASS = "static-glidejs"; // Static class name
  const [apiData1, setApiData1] = useState([]);
  const [apiData2, setApiData2] = useState<{ name: string; color: string; desc: string; featuredImage: string; }[]>([]);
  const [loading, setLoading] = useState(true);
useEffect(() => {
  const apiUrl1 = api_url+'getproductsbycat';
  const apiUrl2 =  api_url+'categorylist';

  
  Promise.all([axios.get(apiUrl1), axios.get(apiUrl2)])
      .then(([response1, response2]) => {
        // Handle the data from the first API endpoint
        setApiData1(response1.data);

        // Handle the data from the second API endpoint
        // setApiData2(response2.data);


        const colors = ["bg-yellow-50", "bg-green-50", "bg-red-50", "bg-blue-50"];
        let arr = [];
        for (let i = 0; i < response2.data.length; i++) {
          const colorIndex = i % colors.length;
          arr.push({
            "id": response2.data[i].id,
            "name": response2.data[i].salecollection,
            "desc": response2.data[i].name,
            "description": response2.data[i].description,
            "featuredImage": api_assets_url+"uploads/" + response2.data[i].categoryimage,
            "color": colors[colorIndex]
          });
        }
        setApiData2(arr);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });
  }, []);
  return (
    <div className="nc-PageHome relative overflow-hidden">
      {/* SECTION HERO */}
      {/* <SectionHero2 /> */}

      <div className="mt-10 lg:mt-10">
      {loading ? 
        <></>
        :
        <DiscoverMoreSlider apiData={apiData2} />
        }
      </div>

      <div className="container relative space-y-24 my-24 lg:space-y-32 lg:my-32">
        {/* SECTION */}
        {loading ? 
        <></>
        :
        <SectionSliderProductCard
          data={apiData1}
        />
        }

        <div className="py-24 lg:py-32 border-t border-b border-slate-200 dark:border-slate-700">
          <SectionHowItWork />
        </div>

        {/* SECTION */}
        {/* <SectionPromo1 /> */}

        {/* SECTION */}
        {/* <div className="relative py-24 lg:py-32">
          <BackgroundSection />
          <SectionGridMoreExplore/>
        </div> */}

        {/* SECTION */}
        <SectionGridFeatureItems />

        {/*  */}
        {/* <SectionPromo2 /> */}

        {/* SECTION 3 */}
        {/* <SectionSliderLargeProduct cardStyle="style2" /> */}

        {/*  */}
        {/* <SectionSliderCategories /> */}

        {/* SECTION */}
        {/* <SectionPromo3 /> */}
        {/* {loading ? 
        <></>
        :
        <SectionSliderProductCard
          heading="Best Sellers"
          subHeading="Best selling of the month"
        />
        } */}
                

        {/* <div className="relative py-24 lg:py-32">
          <BackgroundSection />
          <div>
            <Heading rightDescText="From the Ciseco blog">
              The latest news
            </Heading>
            <SectionMagazine5 />
            <div className="flex mt-16 justify-center">
              <ButtonSecondary>Show all blog articles</ButtonSecondary>
            </div>
          </div>
        </div> */}

        {/*  */}
        <SectionClientSay />
      </div>
    </div>
  );
}

export default PageHome;
