import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import SocialsList from "shared/SocialsList/SocialsList";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionPromo1 from "components/SectionPromo1";
import { api_url } from "common/Helpers";
import { useNavigate } from "react-router-dom";
import SectionMagazine5 from "containers/BlogPage/SectionMagazine5";
export interface PageContactProps {
  className?: string;
}
interface addressdata {
  address: string;
  addresstitle: string;
  email: string;
  emailtitle: string;
  number: number;
  numbertitle: string;
}
// const info = [
//   {
//     title: "🗺 ADDRESS",
//     desc: "AK Beats, Road Number 5, Hyderabad, Telangana, India.",
//   },
//   {
//     title: "💌 EMAIL",
//     desc: "akbeats.2021@gmail.com",
//   },
//   {
//     title: "☎ PHONE",
//     desc: "+91 80999 96966",
//   },
// ];

const PageContact: FC<PageContactProps> = ({ className = "" }) => {
  const [FetchedData, setFetchedData] = useState<addressdata[]>([]);
  const [Email, setEmail] = useState<String>('');
  const [address, setaddress] = useState<String>('');
  const [phonenumer, setphonenumer] = useState<String>('');
  const fetchData = () => {
    fetch(api_url + "getcontact")
      .then((response) => response.json())
      .then((data) => {
        setEmail(data.email);
        setaddress(data.address);
        setphonenumer(data.number);
      })
      .catch((error) => console.error('Error fetching data:', error));
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div
      className={`nc-PageContact overflow-hidden ${className}`}
      data-nc-id="PageContact"
    >
      <Helmet>
        <title>Contact || Shop - eCommerce React Template</title>
      </Helmet>
      <div className="">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Contact
        </h2>
        <div className="container max-w-7xl mx-auto">
          <div className="flex-shrink-0 grid grid-cols-1 md:grid-cols-2 gap-12 ">
            <div className="max-w-sm space-y-8">
                <div>
                  <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                    🗺 ADDRESS
                  </h3>
                  <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                    {address}
                  </span>
                </div>

                <div>
                  <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                    💌 EMAIL
                  </h3>
                  <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                    {Email}
                  </span>
                </div>

                <div>
                  <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                    ☎ PHONE
                  </h3>
                  <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                    {phonenumer}
                  </span>
                </div>

              <div>
                <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                  🌏 SOCIALS
                </h3>
                <SocialsList className="mt-2" />
              </div>
            </div>
            <div>
              <form className="grid grid-cols-1 gap-6" action="#" method="post">
                <label className="block">
                  <Label>Full name</Label>

                  <Input
                    placeholder="Your Name"
                    type="text"
                    className="mt-1"
                  />
                </label>
                <label className="block">
                  <Label>Email address</Label>

                  <Input
                    type="email"
                    placeholder="Your Email"
                    className="mt-1"
                  />
                </label>
                <label className="block">
                  <Label>Message</Label>

                  <Textarea className="mt-1" rows={6} />
                </label>
                <div>
                  <ButtonPrimary type="submit">Send Message</ButtonPrimary>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <br></br>
      {/* OTHER SECTIONS */}
      <div className="container">
        {/* <div className="relative my-24 lg:my-32 py-24 lg:py-32">
          <BackgroundSection />
          <SectionPromo1 />
        </div> */}
      </div>
    </div>
  );

};

export default PageContact;
