export const app_name ="AK Clothing";
export const app_url ="/";
export const api_url ="https://akbeatsclothing.com/makbeatsclothin/api/";
export const api_assets_url ="https://akbeatsclothing.com/makbeatsclothin/storage/app/public/";
// export const api_url ="https://onecommerce.in/monecommerce/api/";
// export const api_assets_url ="https://onecommerce.in/monecommerce/storage/app/public/";
// export const api_url ="http://localhost/akcapi/api/";
// export const api_assets_url ="http://localhost/akcapi/storage/app/public/";

fetch(api_url + "getwebsite")
  .then(response => {
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  })
  .then(data => {
    console.log(data);
  })
  .catch(error => {
    console.error('Error fetching data:', error);
  });
export function getCurrentDate(Date){
    let separator = '-';
    let newDate = Date;
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    let datenow =  `${date<10?`0${date}`:`${date}`}${separator}${month<10?`0${month}`:`${month}`}${separator}${year}`
    return datenow;
    // let datenow =  `${year}${separator}${month<10?`0${month}`:`${month}`}${separator}${date}`
  }
